import { gql } from '@apollo/client';

export const UpdateUserInput = gql`
  input UpdateUserInput {
    id: String
    firstName: String
    lastName: String
    email: String
    phone: String
    jobTitle: String
    mobilePhone: String
    bio: String
    quote: String
    linkedinURL: String
    photo: String
  }
`;

export const ADD_TAB = gql`
mutation($createGoalSheetTabDto: CreateGoalSheetTabDto!) {
  addTab(createGoalSheetTabDto: $createGoalSheetTabDto) {
    tabs
    message
    status
  }
}`;

export const REMOVE_TAB = gql`
mutation($removeGoalSheetTabDto: RemoveGoalSheetTabDto!) {
  removeTab(removeGoalSheetTabDto: $removeGoalSheetTabDto) {
    tabs
    message
    status
  }
}`;

export const FETCH_CURRENT_USER = gql`
  query FetchCurrentUser {
    me {
      user {
        id
        firstName
        lastName
        fullName
        email
        phone
        status
        jobTitle
        intersellerUserId
        userSelectedTheme
        mobilePhone
        bio
        quote
        linkedinURL
        namePrefix
        rank
        canImpersonate
        photo
        outLookToken
        outLookAccountingToken
        outLookAccountingMail
        outLookMail
        docusignAccessToken
        userManager {
          manager {
            fullName
            email
            id
          }
        }
        earningGoal
        intersellerCSVPermissions
        isFeatured
        userRoles {
          deletedAt
          role {
            name
          }
        }
      }
    }
  }
`;

//[Mutation] for reset password
export const USER_UPDATE = gql`
  mutation UserUpdate($userInput: UpdateUserInput!) {
    userUpdate(userInput: $userInput) {
      firstName
    }
  }
`;
export const UPDATE_USER_THEME = gql`
  mutation userTheme($userThemeInput: userThemeInput!) {
    userTheme(userThemeInput: $userThemeInput) {
      status
      message
    }
  }
`;

//Mutation for udpate user password
export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($oldPassword: String!, $newPassword: String!) {
    updateUserPassword(
      updatePasswordInput: {
        oldPassword: $oldPassword
        newPassword: $newPassword
      }
    ) {
      status
      message
    }
  }
`;

//Mutation for udpate user password
export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      message
      status
    }
  }
`;

//query for microsoft account login
export const OUTLOOK_LOGIN = gql`
  query OutLookLogin($code: String!,$accounting:Boolean) {
    gettingOutLookLogin(code: $code,accounting:$accounting) {
      message
      status
    }
  }
`;
export const OUTLOOK_LOGOUT = gql`
  mutation OutLookLogout($id: String!,$accounting:Boolean) {
    clearOutlookAccount(id: $id, accounting:$accounting) {
      message
      status
    }
  }
`;
export const ADD_TJLOOKUP_VIDEOS = gql`
  mutation addTjLookUpVideos($CreateTjLookUpVideos: CreateTjLookUpVideosArr!) {
    addTjLookUpVideos(CreateTjLookUpVideos: $CreateTjLookUpVideos) {
      message
      status
    }
  }
`;
export const UPDATE_TJLOOKUP_VIDEOS = gql`
  mutation updateTjLookUpVideos($UpdateTjLookUpVideos: UpdateTjLookUpVideos!) {
    updateTjLookUpVideos(UpdateTjLookUpVideos: $UpdateTjLookUpVideos) {
      message
      status
    }
  }
`;
export const REMOVE_TJLOOKUP_VIDEO = gql`
  mutation removeTjlookUpVideo($removeTjlookUpVideo: removeTjlookUpVideo!) {
    removeTjlookUpVideo(removeTjlookUpVideo: $removeTjlookUpVideo) {
      message
      status
    }
  }
`;
export const TJLOOKUP_VIDEOS_LIST = gql`
  query tjlookUpvideoList($page: Int!, $limit: Int!, $searchText: String!) {
    tjlookUpvideoList(
      payload: { page: $page, limit: $limit, searchText: $searchText }
    ) {
      Tjlookup {
        id
        videoTitle
        videoUrl
      }
      count
    }
  }
`;


export const GET_EXPIRY_REPORT = gql`
query GetExpiryReport($payload: CompanyExpiryReportInput!) {
  getCompanyExpiryReport(payload: $payload) {
    companyExpiryReports{
      userId
      companyName
      companyId
      am
      endedAt
      note
      daysToExpiration
      contact {
        fullName
        preferredContact
        contactPhone {
          id
          number
          isPrimary
        }
        contactEmail {
          id
          email
          isPrimary
        }
      }
    }
    totalCount
    }
  }
`;

export const GET_CANDIDATE_EXPIRY_REPORT = gql`
query GetExpiryReport($payload: CandidateExpiryReportInput!) {
  getCandidateExpiryReport(payload: $payload) {
    reports {
      candidateId
      name
      recruiter
      expirationDate
      recentEmployment
      recentCompany
      primaryEmail
      primaryPhone
      daysToExpiration
    }
    count
  }
}
`

export const GET_REAL_REPP_DOCUMENTS = gql`
query GetRealReppDocumentsByCategory($getRealreppDocuments: GetRealReppDocumentsPagination!) {
  getRealreppDocumentsByCategory(getRealreppDocuments: $getRealreppDocuments) {
    message
    status
    documents {
      name
      data {
        id
        name
        link
        category
        createdAt
        updatedAt
      }
    }
    total
  }
}
`;

export const GET_SIGNED_URL = gql`
query GetSignedUrl($url: String!) {
  getSignedUrl(url: $url) {
    message
    status
    signedUrl
  }
}`;

export const DELETE_DOCUMENTS = gql`
mutation DeleteRealreppDocument($deleteRealreppDocument: DeleteRealReppDocumentInputDTO!) {
  deleteRealreppDocuments(deleteRealreppDocument: $deleteRealreppDocument) {
    message
    status
    document {
      id
      name
      link
      category
      createdAt
      updatedAt
    }
  }
}
`;

//activity report
export const GET_USER_ACTIVITY_REPORT = gql`
query userActivityReports($page: Int!, $limit: Int!,$columnFilters:UserActivityReportColumnFilter!) {
  userActivityReports(payload: { limit: $limit, page: $page },columnFilters:$columnFilters) {
    userActivityReports {
      id
      month
      year
      documentLink
      userId
      user {
        id
        fullName
        email
      }
    }
    count
    message
    status
  }
}`

export const CREATE_REPORT = gql`
mutation($input: CreateUserActivityReportInput!) {
  createUserActivityReport(input: $input) {
    status
    status
  }
}`

export const UPDATE_REPORT = gql`
mutation($input: UpdateUserActivityReportInput!) {
  updateUserActivityReport(input: $input) {
    status
    status
  }
}`

export const DELETE_RECORD = gql`
mutation($id: String!) {
  removeUserActivityReport(id: $id) {
    message
    status
  }
}`

export const EMAIL_USER_ACTIVITY_REPORT = gql`
mutation($input: EmailUserActivityReport!) {
  sendEmail(input: $input) {
    message
    status
  }
}`

export const GET_CC_EMAILS = gql`
query($id: String!) {
  getUserManagerEmail(id: $id)
}
`
//getting single template on basis on module
export const GET_SINGLE_TEMPLATE = gql`
query($module: String!) {
  getTemplateByModule(module: $module) {
    template
    subject
  }
}
`