import { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Badge,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap';
import { formatDateUS } from '../../../../utils/commonFn';
import {
  commaRemover,
  localStringMaker,
  moneyFormatter,
  pushInHistory,
} from '../../../../utils/helper';
import { IAccountManagerPercentage } from '../create/IndexUpdatePlacement';
import {
  APPROVE_EXISTING_RECRUITER_SPLIT,
  CREATE_PLACEMENT_RECRUITER_SPLIT,
  CREATE_PLACEMENT_SPLIT,
  ON_APPROVE_PLACEMENT,
  PLACEMENT_CONTACT,
  UPDATE_PLACEMENT,
} from '../gql';
import EMPTY_ICON from '../../../../assets/images/empty-box.svg';
import { IComingState } from '../../../agreements/interfaces';
import { ON_REJECT_PLACEMENT } from '../gql';
import { CREATE_NOTES } from '../../../company/gql';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../context';
import CustomButton from '../../../../components/layout/CustomButton';
import EditPlacement from './EditPlacement';
import { useForm } from 'react-hook-form';
import { UpdatePlacementInput } from '../placementInterface';

import CLOSE_ICON from '../../../../assets/images/close.svg';
import AccountManagerSplit from '../components/AccountManagerSplit';
import { FETCH_ALL_ACTIVE_RECRUITERS } from '../../../admin/gql';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import RecruiterSplit from '../components/RecruiterSplit';
import RejectNotes from '../components/RejectNotesModal';
import { CONTACT_DETAIL_ROUTE, PAID, PERCENTAGE, PLACEMENT_MANAGEMENT_ROUTE, VIEW_PLACEMENT_MANAGEMENT_ROUTE } from '../../../../utils/constant';
import { IRecruiterPercentage } from '../../../../interfaces';

export default function ViewPlacementSteps({
  viewingAccountManagerArr,
  viewingRecruiterList,
  viewData,
  accountManagerListQuery,
  recruiterListQuery,
  setRecruiterList,
  refetchingPlacement,
  refetchingPlacementSplit,
  splitEdit,
  recruiterSplitEdit,
  defaultCandidateOwner,
  defaultPercentage,
  setAccountManagerList,
  defaultOwner,
  companyOwnerAndShares,
  placementStatus,
  placementSplitData,
}) {
  const location = useLocation<IComingState>();
  const history = useHistory();

  const {
    control,
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    setError,
    setValue,
  } = useForm();

  const [_onApprovingPlacement] = useMutation(ON_APPROVE_PLACEMENT);

  const [fetchUsers, { data: fetchingAllUsers }] = useLazyQuery(
    FETCH_ALL_ACTIVE_RECRUITERS
  );
  const [recruiterSplitConfirmation, setRecruiterSplitConfirmation] =
    useState<boolean>(false);

  const [_createPlacementSplit, { loading: splitLoading }] = useMutation(
    CREATE_PLACEMENT_SPLIT
  );
  const [_approveExistingRecruiterSplit, { loading }] = useMutation(
    APPROVE_EXISTING_RECRUITER_SPLIT
  );

  const [_placementContact, { data: _placementContactData }] =
    useLazyQuery(PLACEMENT_CONTACT);

  useEffect(() => {
    if (viewData?.placementType === 'CONTRACT' && viewData.id) {
      _placementContact({ variables: { placementId: viewData?.id } });
    }

    fetchUsers({
      variables: {
        limit: 20,
        page: 1,
      },
    });

    // eslint-disable-next-line
  }, []);

  const { user, userRoles } = useContext(AppContext);

  const { id }: any = useParams();
  const [accountManagerSplitConfirmation, setAccountManagerSplitConfirmation] =
    useState<boolean>(false);
  const [accountManagersManagerApproval, setAccountManagersManagerApproval] =
    useState<boolean>(false);

  const [_updatePlacement] = useMutation(UPDATE_PLACEMENT);

  const [editModal, setEditModal] = useState<boolean>(false);
  const [accountManagerModal, setAccountManagerModal] =
    useState<boolean>(false);
  const [recruiterModal, setRecruiterModal] = useState<boolean>(false);
  const [_createPlacementRecruiterSplit, { loading: recruiterLoading }] =
    useMutation(CREATE_PLACEMENT_RECRUITER_SPLIT);

  const [editPermitForAM, setEditPermitForAM] = useState<boolean>(false);
  const [placementEditPermit, setPlacementEditPermit] =
    useState<boolean>(false);
  const [editPermitForRecruiter, setEditPermitForRecruiter] =
    useState<boolean>(false);
  const [reject, setReject] = useState<boolean>(false);
  const [_onRejectPlacement] = useMutation(ON_REJECT_PLACEMENT);
  const [createNotes, { loading: reactLoading }] = useMutation(CREATE_NOTES);

  const onEditHandler = () => {
    setEditModal(!editModal);
  };

  const onAddEditAccountManagerHandler = () => {
    togglePlacementAccountManagerSplit();
  };

  const onAddEditRecruiterHandler = () => {
    togglePlacementRecruiterSplit();
  };

  useEffect(() => {
    let currentDate = new Date();
    let comparisonDate = new Date(viewData?.startDate);
    if (viewData && companyOwnerAndShares?.length) {
      let ownerAndShares = companyOwnerAndShares?.map(
        (item) => item?.owner?.id
      );
      if (viewData?.placementStatus !== 'ACCOUNTING_INVOICED' && viewData?.placementStatus !== PAID) {
        if (
          viewData?.recruiter?.id === user?.id &&
          viewData?.placementStatus === 'PENDING_RECRUITER'
        ) {
          // setPlacementEditPermit(true);
          setEditPermitForRecruiter(true);
        }

        if (
          ownerAndShares?.includes(viewData?.recruiter?.id) &&
          viewData?.recruiter?.id === user?.id
          // ownerAndShares?.includes(user?.id)
        ) {
          setPlacementEditPermit(true);
          setEditPermitForRecruiter(true);
          setEditPermitForAM(true);
        }

        if (ownerAndShares?.includes(user?.id)) {
          setPlacementEditPermit(true);
          setEditPermitForAM(true);
        }

        if (
          viewData?.managerOfAM?.id === user?.id &&
          viewData?.placementStatus === 'PENDING_MANAGER'
        ) {
          setEditPermitForRecruiter(true);
          setEditPermitForAM(true);
        }
      }
    }
    if (viewData) {
      if (viewData?.accountManager?.id === user?.id) {
        setPlacementEditPermit(true);
      }
    }

    if (viewData?.accountManager?.id === user?.id && (viewData?.placementStatus === 'ACCOUNTING_APPROVED' || viewData?.placementStatus === 'ACCOUNTING_INVOICED' || viewData?.placementStatus === PAID) && (currentDate > comparisonDate)) {
      setPlacementEditPermit(false);
    }

    if ((viewData?.accountManager?.id === user?.id || viewData?.recruiter?.id === user?.id) &&
      (viewData?.placementStatus === 'ACCOUNTING_APPROVED' || viewData?.placementStatus === 'ACCOUNTING_INVOICED' || viewData?.placementStatus === PAID) &&
      (currentDate > comparisonDate)
    ) {
      setEditPermitForAM(false)
    }





    // eslint-disable-next-line
  }, [viewData, companyOwnerAndShares]);

  const accountManagerSplitConfirmationToggle = () => {
    setAccountManagerSplitConfirmation(!accountManagerSplitConfirmation);
  };

  const onPlacementEditSubmit = async (values: any) => {
    const endingDate = moment(values?.startDate)
      .add(parseInt(values?.assignedDuration) * 7, 'days')
      .format('YYYY-MM-DD');

    let finalValues: UpdatePlacementInput = {
      id: viewData.id,
      noteToAccounting: values?.noteToAccounting,
      placementNote: values?.placementNote,
      startDate: values?.startDate,
      estimateEndDate:
        values?.placementType?.value === 'DIRECT_HIRE'
          ? new Date()
          : endingDate,
      source: values?.placementSource,
      placementType: values?.placementType?.value,
    };

    if (values?.placementType?.value === 'DIRECT_HIRE') {
      const salary = commaRemover(values?.salary);

      const finalSalaryAmount =
        values?.salaryType === 'HOURLY'
          ? commaRemover(values?.salary) * 40 * 52
          : salary;

      const feeStructured = commaRemover(values?.feeStructured);

      let totalCommission = commaRemover(
        watch('commissionType')?.value === 'FLAT_FEE'
          ? localStringMaker(commaRemover(watch('feeStructured')))
          : localStringMaker(
            commaRemover(
              watch('salaryType') === 'ANNUALLY'
                ? watch('salary')
                : localStringMaker(commaRemover(watch('salary')) * 40 * 52)
            ) *
            commaRemover(watch('feeStructured')) *
            (1 / 100)
          )
      );

      const adjustmentAmount = commaRemover(values?.adjustmentAmount);
      const netCommission = commaRemover(
        watch('adjustmentAmount')
          ? localStringMaker(
            commaRemover(watch('totalFee')) -
            commaRemover(watch('adjustmentAmount'))
          )
          : localStringMaker(commaRemover(watch('totalFee')))
      );

      finalValues = {
        ...finalValues,
        adjustmentTotal: adjustmentAmount,
        adjustmentsReason: values?.adjustmentReason,
        feeStructured,
        agreement: values?.agreement?.value,
        commissionType: values?.commissionType?.value,
        salaryAmount: finalSalaryAmount,
        salaryType: values?.salaryType,
        payableTerm: values?.payableDays,
        replacementTerm: values?.replacementDays,
        totalCommission,
        netCommission,
      };
    } else {
      // Rate calculation
      const estimatedLessPayrollBurden = viewData?.estimatedLessPayrollBurdenPercenatge ? (viewData?.estimatedLessPayrollBurdenPercenatge / 100) * commaRemover(values?.payRate) : 0.1366 * commaRemover(values?.payRate); // becouase from now out estimatedLessPayrollBurden will be depend on diffrent condition thats why we save it in new field. For more Details please visit Ticket is ATS-900 
      const insuranceCost = 0
      const lessAcaCose = 0
      const peopleTwoFee = 0.05 * commaRemover(values?.billRate);
      const estimatedRecruiterProfit =
        commaRemover(values?.billRate) -
        commaRemover(values?.payRate) -
        estimatedLessPayrollBurden -
        lessAcaCose -
        peopleTwoFee;

      finalValues = {
        ...finalValues,
        assignmentDuration: parseFloat(values?.assignedDuration),
        billRate: commaRemover(values?.billRate),
        markupPercentage: parseFloat(values?.markupPercentage),
        overtimeBillRate: commaRemover(values?.overtimeBillRate),
        payRate: commaRemover(values?.payRate),
        timeCardApprover: values?.timeCardApprover?.value,
        estimatedLessPayrollBurden,
        insuranceCost,
        peopleTwoFee,
        estimatedRecruiterProfit,
      };
    }

    const placementUpdateSaving = await _updatePlacement({
      variables: {
        updatePlacementDTO: finalValues,
      },
    });

    if (placementUpdateSaving?.data) {
      toast.success('Placement is updated successfully');
      setEditModal(!editModal);
      refetchingPlacement();
    }
  };

  const accountManagerListChange = (
    accountManagerPercentage: IAccountManagerPercentage
  ) => {
    const currentIndex = viewingAccountManagerArr.indexOf(
      accountManagerPercentage
    );
    const newChecked = [...viewingAccountManagerArr];

    if (currentIndex === -1) {
      newChecked.push(accountManagerPercentage);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAccountManagerList(newChecked);
  };

  const onAccountManagerSplitting = async () => {
    const accountManagers = [...viewingAccountManagerArr].map((item) => {
      return {
        accountManager: item?.accountManager?.value,
        percentage: item.percentage,
      };
    });

    const accountManagersplit = await _createPlacementSplit({
      variables: {
        createPlacementSplitDTO: {
          placementId: viewData.id,
          accountManagers,
        },
      },
    });

    if (accountManagersplit?.data) {
      refetchingPlacementSplit();
      accountManagerSplitConfirmationToggle();
      togglePlacementAccountManagerSplit();
      toast.success(
        `Account manager placement split ${splitEdit ? 'updated' : 'added'
        } successfully`
      );
      refetchingPlacement();
    }
  };

  const recruiterSplitConfirmationToggle = () => {
    setRecruiterSplitConfirmation(!recruiterSplitConfirmation);
  };

  const onRecruiterSplitting = async () => {
    const recruiters = [...viewingRecruiterList].map((item) => {
      return {
        recruiter: item?.recruiter?.value,
        percentage: item.percentage,
      };
    });

    const recruiterSplit = await _createPlacementRecruiterSplit({
      variables: {
        createPlacementRecruiterSplitDTO: {
          placementId: viewData.id,
          isHigherAuthority:
            userRoles?.includes('ADMIN') ||
              userRoles?.includes('EXECUTIVE') ||
              userRoles?.includes('ACCOUNTING_MANAGER')
              ? true
              : false,
          recruiters,
        },
      },
    });

    if (recruiterSplit?.data) {
      recruiterSplitConfirmationToggle();
      refetchingPlacementSplit();
      togglePlacementRecruiterSplit();
      toast.success(
        `Recruiter placement split ${recruiterSplitEdit ? 'updated' : 'added'
        } successfully`
      );
      refetchingPlacement();
    }
  };

  const togglePlacementAccountManagerSplit = () => {
    setAccountManagerModal(!accountManagerModal);
  };

  const togglePlacementRecruiterSplit = () => {
    setRecruiterModal(!recruiterModal);
  };

  const recruiterListChange = (recruiterPercentage: IRecruiterPercentage) => {
    const currentIndex = viewingRecruiterList.indexOf(recruiterPercentage);
    const newChecked = [...viewingRecruiterList];

    if (currentIndex === -1) {
      newChecked.push(recruiterPercentage);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRecruiterList(newChecked);
  };

  const onApprovingPlacement = async () => {
    const approveRes = await _onApprovingPlacement({
      variables: {
        id,
      },
    });
    if (approveRes.data) {
      toast.success(
        'Placement is approved and passed to accounts person for further steps'
      );

      setTimeout(() => {
        history.push(PLACEMENT_MANAGEMENT_ROUTE);
      }, 800);
    }
  };

  const toogleReject = () => {
    setReject(!reject);
  };

  const onRejectPlacement = async (values: any) => {
    const response = await createNotes({
      variables: {
        noteableType: 'Candidate',
        noteableId: viewData?.candidate?.id,
        category: `Placement - Manager Rejected`,
        content: values?.content,
      },
    });

    if (response?.data) {
      const rejectRes = await _onRejectPlacement({
        variables: {
          id,
          reason: values?.content,
        },
      });

      if (rejectRes?.data) {
        toast.success(
          'Placement is rejected and moved to Recruiter Pending state'
        );
        toogleReject();
        history.push(PLACEMENT_MANAGEMENT_ROUTE);
      }
    }
  };

  return (
    <Fragment>
      <div className="jobOrderRevamp placementDetail">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="mobileResponsiveFlexAlign w-100 justify-content-between">
            <div className="d-flex align-items-center">
              <h5 className="mr-2 noMarginHeading">Placement Detail</h5>

              <Badge
                color={
                  viewData?.placementStatus === 'ACCOUNTING_APPROVED' ||
                    viewData?.placementStatus === 'ACCOUNTING_CONFIRMED' ||
                    viewData?.placementStatus === 'ACCOUNTING_INVOICED' ||
                    viewData?.placementStatus === PAID

                    ? 'success'
                    : viewData?.placementStatus === 'PROSPECT'
                      ? 'warning'
                      : viewData?.placementStatus === 'DRAFT' ||
                        viewData?.placementStatus === 'ASSIGNMENT_COMPLETED' ||
                        viewData?.placementStatus === 'ASSIGNMENT_CONVERTED'
                        ? 'primary'
                        : viewData?.placementStatus === 'REJECTED_MANAGER' ||
                          viewData?.placementStatus === 'REJECTED_AM' ||
                          viewData?.placementStatus === 'REJECTED_ACCOUNTING' ||
                          viewData?.placementStatus === 'REJECTED_RECRUITER' ||
                          viewData?.placementStatus === 'PLACEMENT_CANCELLED' ||
                          viewData?.placementStatus === 'ASSIGNMENT_CANCELLED'
                          ? 'danger'
                          : viewData?.placementStatus === 'PENDING_MANAGER' ||
                            viewData?.placementStatus === 'PENDING_AM' ||
                            viewData?.placementStatus === 'PENDING_RECRUITER' ||
                            viewData?.placementStatus === 'PENDING_ACCOUNTING'
                            ? 'info'
                            : 'primary'
                }
                className="text-center"
              >
                {viewData?.placementStatus}
              </Badge>
            </div>

            <div>
              {placementEditPermit && placementStatus !== 'PLACEMENT_ENDED' ? (
                <button
                  type="button"
                  className="buttonGenericStyle outlinedButtonDark"
                  onClick={onEditHandler}
                >
                  Edit
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div>
          {viewData?.placementType === 'DIRECT_HIRE' ? (
            <Row>
              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Invoice ID</label>

                  <h6>{viewData?.invoiceNumber || "--"}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Start Date</label>

                  <h6>
                    {viewData?.startDateString &&
                      formatDateUS(moment(viewData?.startDateString).format())}
                  </h6>
                </div>
                {viewData?.placementEndDate ? (
                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>End Date</label>

                    <h6>
                      {viewData?.startDateString &&
                        formatDateUS(
                          moment(viewData?.placementEndDate).format()
                        )}
                    </h6>
                  </div>
                ) : null}

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Placement Source</label>
                  <h6>{viewData?.source || '--'}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Placement Type</label>
                  <h6>{viewData?.placementType || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Salary</label>
                  <h6>{moneyFormatter(viewData?.salaryAmount || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Commission Type</label>
                  <h6>{viewData?.commissionType || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Agreement</label>
                  <h6>{viewData?.agreement?.nickName || '--'}</h6>
                </div>

                <div className="d-flex  mb-2 contentGridLabel">
                  <label>placement Note</label>
                  <h6 className="text-truncate" style={{ maxWidth: '15em' }}>
                    {viewData?.placementNote || '--'}
                  </h6>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Client Fee Rate</label>
                  <h6>
                    {viewData?.feeStructured
                      ? viewData?.commissionType === PERCENTAGE ? `${viewData?.feeStructured}%` : `${moneyFormatter(viewData?.feeStructured || 0)}`
                      : '--'}
                  </h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Replacement Days</label>
                  <h6>{viewData?.replacementTerm || '--'}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Payable Days</label>
                  <h6>{viewData?.payableTerm || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Total Fee</label>
                  <h6>{moneyFormatter(viewData?.totalCommission || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Adjustment Amount</label>
                  <h6>{moneyFormatter(viewData?.adjustmentTotal || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Adjustment Reason</label>
                  <h6>{viewData?.adjustmentsReason || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Net Fee</label>
                  <h6>{moneyFormatter(viewData?.netCommission || 0)}</h6>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Invoice ID</label>

                  <h6>{viewData?.invoiceNumber || "--"}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Start Date</label>

                  <h6>
                    {viewData?.startDateString &&
                      formatDateUS(moment(viewData?.startDateString).format())}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Assignment Duration (Weeks)</label>
                  <h6>
                    {viewData?.assignmentDuration
                      ? viewData?.assignmentDuration === 1
                        ? `${viewData?.assignmentDuration} week`
                        : `${viewData?.assignmentDuration} weeks`
                      : '--'}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Less ACA Cost</label>
                  <h6>
                    ({moneyFormatter(0)}
                    {`/hour`})
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Company Bill Rate</label>
                  <h6>
                    {moneyFormatter(viewData?.billRate || 0)}
                    {`/hour`}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Less Employee Pay Rate</label>
                  <h6>
                    ({moneyFormatter(viewData?.payRate || 0)}
                    {`/hour`})
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Estimated Less Payroll Burden</label>
                  <h6>
                    ({moneyFormatter(viewData?.estimatedLessPayrollBurden || 0)}
                    )
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Less People 2.0 Fee</label>
                  <h6>
                    ({moneyFormatter(viewData?.peopleTwoFee || 0)}
                    /hour)
                  </h6>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Estimated Recruiter Profit</label>
                  <h6>
                    {moneyFormatter(viewData?.estimatedRecruiterProfit || 0)}
                    /hour
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Insurance Cost</label>
                  <h6>{moneyFormatter(viewData?.insuranceCost || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Markup Percentage</label>
                  <h6>{viewData?.markupPercentage?.toFixed(2) || '0'}%</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Overtime Bill Rate</label>
                  <h6>{moneyFormatter(viewData?.overtimeBillRate || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Time Card Approver</label>

                  {!!_placementContactData?.placementContact?.companyContact?.fullName ?
                    <h6 className="route-link"
                      onClick={() => {
                        history.push({
                          pathname: `${CONTACT_DETAIL_ROUTE}/${_placementContactData?.placementContact?.companyContact?.id}`,
                          state: {
                            backUrl: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${viewData?.id}`,
                            searchParameters: location.search
                          },
                        });

                        pushInHistory(`${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${viewData?.id}`)
                      }}>
                      {_placementContactData?.placementContact?.companyContact?.fullName}
                    </h6> : <h6>--</h6>
                  }
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Approx End Date</label>
                  <h6>
                    {viewData?.estimateEndDate &&
                      formatDateUS(viewData?.estimateEndDate)}
                  </h6>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>

      <div className="jobOrderRevamp">
        <Row>
          <Col lg="5" md="5" sm="12" xs="12" className="mb-md-0 mb-3">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ minHeight: '3em' }}
            >
              <h5 className="mb-1">Account Manager Split</h5>

              <div>
                {editPermitForAM && placementStatus !== 'PLACEMENT_ENDED' ? (
                  <button
                    type="button"
                    className="buttonGenericStyle outlinedButtonDark"
                    onClick={onAddEditAccountManagerHandler}
                  >
                    {splitEdit ? 'Edit' : 'Add'}
                  </button>
                ) : null}
              </div>
            </div>

            <div>
              {accountManagerListQuery?.length ? (
                <>
                  <Row>
                    <Col md="12">
                      <div className='dark-table'>
                        <Table
                          className="transparentTable"
                          style={{ borderCollapse: 'separate' }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '40%' }}>Name</th>
                              <th style={{ width: '30%' }}>Credit%</th>
                              <th
                                style={{ width: '30%', textAlign: 'right' }}
                                align="right"
                              >
                                Billing Credit
                              </th>
                            </tr>
                          </thead>
                          {accountManagerListQuery?.map(
                            (item: IAccountManagerPercentage, index: number) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>
                                      <h6>{item?.accountManager?.label}</h6>
                                    </td>
                                    <td>
                                      <h6>{item?.percentage}%</h6>
                                    </td>
                                    <td align="right">
                                      <h6>
                                        {moneyFormatter(
                                          (viewData?.netCommission *
                                            0.5 *
                                            item?.percentage) /
                                          100 || 0
                                        )}
                                      </h6>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            }
                          )}
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="emptyPlaceholder">
                  <img src={EMPTY_ICON} alt="empty" />
                  Split for account manager is not added yet
                </div>
              )}
            </div>
          </Col>

          <Col lg="2" md="2" sm="12" xs="12"></Col>
          <Col lg="5" md="5" sm="12" xs="12">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ minHeight: '3em' }}
            >
              <h5 className="m-0">Recruiters Split</h5>
              <div>
                {!!recruiterListQuery?.length &&
                  editPermitForRecruiter &&
                  viewData?.placementStatus === 'PENDING_RECRUITER' && (
                    <button
                      className="buttonGenericStyle outlinedButtonDark bg-light text-dark mr-2"
                      onClick={async () => {
                        await _approveExistingRecruiterSplit({
                          variables: { placementId: viewData?.id },
                        });
                        refetchingPlacement();
                      }}
                    >
                      {loading && (
                        <Spinner
                          size="sm"
                          style={{ color: '#eaa827' }}
                          className="mr-2"
                        />
                      )}{' '}
                      Approve
                    </button>
                  )}
                {editPermitForRecruiter ? (
                  <button
                    type="button"
                    className="buttonGenericStyle outlinedButtonDark"
                    onClick={onAddEditRecruiterHandler}
                  >
                    {recruiterSplitEdit ? 'Edit' : 'Add'}
                  </button>
                ) : null}
              </div>
            </div>

            <div>
              {recruiterListQuery?.length ? (
                <>
                  <Row>
                    <Col md="12">
                      <div className="dark-table">
                        <Table
                          className="transparentTable"
                          style={{ borderCollapse: 'separate' }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '40%' }}>Name</th>
                              <th style={{ width: '30%' }}>Credit%</th>
                              <th
                                style={{ width: '30%', textAlign: 'right' }}
                                align="right"
                              >
                                Billing Credit
                              </th>
                            </tr>
                          </thead>
                          {recruiterListQuery?.map(
                            (item: IRecruiterPercentage, index: number) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>
                                      <h6>{item?.recruiter?.label}</h6>
                                    </td>
                                    <td>
                                      <h6>{item?.percentage}%</h6>
                                    </td>
                                    <td align="right">
                                      <h6>
                                        {moneyFormatter(
                                          (viewData?.netCommission *
                                            0.5 *
                                            item?.percentage) /
                                          100 || 0
                                        )}
                                      </h6>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            }
                          )}
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="emptyPlaceholder">
                  <img src={EMPTY_ICON} alt="empty" />
                  Split for recruiter is not added yet
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={accountManagerModal}
        toggle={togglePlacementAccountManagerSplit}
        scrollable
        className="for-modal-styling splitDialog"
      >
        <ModalHeader>
          {splitEdit ? 'Update' : 'Add'} Account Manager Splits
          <button
            type="button"
            className="iconButton"
            onClick={togglePlacementAccountManagerSplit}
          >
            <img src={CLOSE_ICON} alt="close" />
          </button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <AccountManagerSplit
              accountManagers={
                fetchingAllUsers?.fetchingAllActiveRecruiters?.users
              }
              errors={errors}
              defaultPercentage={defaultPercentage}
              editPermitForAM={!editPermitForAM}
              accountManagerListChange={accountManagerListChange}
              accountManagerList={viewingAccountManagerArr}
              defaultOwner={defaultOwner}
              netCommission={viewData?.netCommission}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={togglePlacementAccountManagerSplit}
            type="button"
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>

          <>
            {editPermitForAM && (
              <CustomButton
                disabled={
                  viewingAccountManagerArr?.length &&
                    viewingAccountManagerArr?.reduce(function (acc, curr) {
                      return acc + curr.percentage;
                    }, 0) === 100
                    ? false
                    : true
                }
                buttonText={`${splitEdit ? 'Update' : 'Add'} & Save`}
                buttonColor={'primary'}
                buttonType="button"
                buttonClick={accountManagerSplitConfirmationToggle}
              />
            )}
          </>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={recruiterModal}
        toggle={togglePlacementRecruiterSplit}
        scrollable
        className="for-modal-styling splitDialog"
      >
        <ModalHeader className="pb-0">
          {recruiterSplitEdit ? 'Edit' : 'Add'} Recruiter Split
          <button
            type="button"
            className="iconButton"
            onClick={togglePlacementRecruiterSplit}
          >
            <img src={CLOSE_ICON} alt="close" />
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="noteSection mt-0 light-dark-color">
            <h5 className="shortTextWidth">Recruiter Splits</h5>
          </div>

          <Form>
            <RecruiterSplit
              recruiters={fetchingAllUsers?.fetchingAllActiveRecruiters?.users}
              errors={errors}
              recruiterListChange={recruiterListChange}
              recruiterList={viewingRecruiterList}
              defaultCandidateOwner={defaultCandidateOwner}
              editPermitForRecruiter={!editPermitForRecruiter}
              defaultPercentage={defaultPercentage}
              netCommission={viewData?.netCommission}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={togglePlacementRecruiterSplit}
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>

          <>
            {editPermitForRecruiter && (
              <CustomButton
                disabled={
                  viewingRecruiterList?.length &&
                    viewingRecruiterList?.reduce(function (acc, curr) {
                      return acc + curr.percentage;
                    }, 0) === 100
                    ? false
                    : true
                }
                buttonText={`${recruiterSplitEdit ? 'Update' : 'Add'} & Save`}
                buttonColor={'primary'}
                buttonType="button"
                buttonClick={recruiterSplitConfirmationToggle}
              />
            )}
          </>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        scrollable
        className="for-modal-styling editPlacementDialog"
      >
        <ModalHeader toggle={() => setEditModal(!editModal)}>
          Edit placement details
          <button
            className="iconButton"
            onClick={() => setEditModal(false)}
            type="button"
          >
            <img src={CLOSE_ICON} alt="close" />
          </button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <EditPlacement
              location={location}
              watch={watch}
              setValue={setValue}
              control={control}
              register={register}
              errors={errors}
              reset={reset}
              setError={setError}
              viewData={viewData}
              readonly={!placementEditPermit}
              setEditModal={setEditModal}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => setEditModal(false)}
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>

          <CustomButton
            buttonText="Update & Save"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            buttonClick={handleSubmit(onPlacementEditSubmit)}
          />
        </ModalFooter>
      </Modal>

      <ConfirmPopUp
        confirmText={`Are you sure you want to ${splitEdit ? 'update' : 'create'
          } account manager split?`}
        isOpen={accountManagerSplitConfirmation}
        toggle={accountManagerSplitConfirmationToggle}
        confirmAction={onAccountManagerSplitting}
        modalHeading="Confirmation Action"
        btnText={`${splitEdit ? 'Update' : 'Create'}`}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
        loading={splitLoading}
        disabled={splitLoading}
      />

      <ConfirmPopUp
        confirmText={`Are you sure you want to ${recruiterSplitEdit ? 'update' : 'create'
          } recruiter split?`}
        isOpen={recruiterSplitConfirmation}
        toggle={recruiterSplitConfirmationToggle}
        confirmAction={onRecruiterSplitting}
        modalHeading="Confirmation Action"
        btnText={`${recruiterSplitEdit ? 'Update' : 'Create'}`}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
        loading={recruiterLoading}
        disabled={recruiterLoading}
      />

      <ConfirmPopUp
        confirmText={'Are you sure you want to approve the placement?'}
        isOpen={accountManagersManagerApproval}
        toggle={() => {
          setAccountManagersManagerApproval(!accountManagersManagerApproval);
        }}
        confirmAction={onApprovingPlacement}
        modalHeading="Confirmation Action"
        btnText={'Approve'}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <RejectNotes
        modal={reject}
        toggle={toogleReject}
        onRejectConfirm={onRejectPlacement}
        loading={reactLoading}
      />
    </Fragment>
  );
}
