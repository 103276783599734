// packages block
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
// components block
import InputField from '../../../components/layout/InputField';
import Loader from '../../../components/layout/Loader';
import Pagination from '../../../components/layout/Pagination';
import TableComponent from '../../../components/layout/TableComponent';
// constants, utils and graphql block
import { useForm } from 'react-hook-form';
import EDIT_ICON from '../../../assets/images/edit-icon-menu.svg';
import avatarSvg from '../../../assets/images/user-empty.svg';
import NoDataFound from '../../../components/layout/NoDataFound';
import { USER_TABLE_HEADER } from '../../../constants';
import { PAGE_LIMIT } from '../../../utils/constant';
import { getUserStatusColor } from '../../../utils/helper';
import { FETCHING_ALL_USERS_WITH_PAGINATION } from '../gql';

const Users = ({ location, history, searchText, userStatus }: any): JSX.Element => {
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [persistedPage, setPersistedPage] = useState(_currentPage);
  const [limit, setLimit] = useState<number>(10);
  const [fetchUsers, { loading, error, data }] = useLazyQuery(
    FETCHING_ALL_USERS_WITH_PAGINATION
  );

  useEffect(() => {
    if (!searchText) {
      setCurrentPage(persistedPage);
    } else if (searchText?.length > 2) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (!searchText || searchText?.length > 2) {
      history.push(`/admin/users?currentPage=${currentPage}&&limit=${limit}`);
      fetchUsers({
        variables: {
          userInput: {
            searchText,
            limit,
            page: currentPage,
            userStatus
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit, searchText, userStatus]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="table-responsive">
        <TableComponent tableHeader={USER_TABLE_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !data?.fetchAllUsers?.users?.length && (
            <tbody>
              <tr>
                <td colSpan={11} className="empty-table-td">
                  <NoDataFound text="No User Found!" />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {data?.fetchAllUsers &&
              data?.fetchAllUsers?.users.map((user: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div className="avatar-profile">
                      <div className="avatar avatar-team">
                        <img src={user?.photo || avatarSvg} alt="team" />
                      </div>
                      {user?.fullName}
                    </div>
                  </td>

                  <td>
                    <a href={user?.email ? `mailto:${user.email}` : '#'}>
                      {user.email}
                    </a>
                  </td>
                  <td>
                    <a href={user?.phone ? `tel:${user.phone}` : '#'}>
                      {user.phone}
                    </a>
                  </td>
                  <td>
                    {user.userRoles && user.userRoles.length ? (
                      user.userRoles.map((roleOfuser: any, index: number) => (
                        <span
                          className="text-capitalize"
                          key={`roles_${index}`}
                        >
                          {`${roleOfuser.role.name}`.toLowerCase()} &nbsp;&nbsp;
                        </span>
                      ))
                    ) : (
                      <span className="text-dark mx-2">- -</span>
                    )}
                  </td>
                  <td>
                    <Badge
                      className="text-capitalize"
                      color={getUserStatusColor(user.status)}
                    >
                      {user.status.toLowerCase()}
                    </Badge>
                  </td>
                  <td>
                    {user.userRoles.includes('Admin') ||
                      user.userRoles.includes('Executive') ? (
                      <Button
                        className="text-danger"
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          fontSize: 12,
                          paddingLeft: 0,
                        }}
                        disabled={true}
                      >
                        <img src={EDIT_ICON} alt="edit" width="15px" />
                      </Button>
                    ) : (
                      <Link to={`/admin/edit-user/${user.id}`}>
                        <img src={EDIT_ICON} alt="edit" width="15px" />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableComponent>
      </div>

      {!loading && data?.fetchAllUsers?.count ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              setPersistedPage(pageClicked);
              history.push(`/admin/users?currentPage=${pageClicked}`);
            }}
            pageCount={Math.ceil(data?.fetchAllUsers?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.fetchAllUsers?.count)} of{' '}
                {data?.fetchAllUsers?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {error?.graphQLErrors[0]?.message}
    </div>
  );
};

export default withRouter(Users);
