import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import InputField from '../../../../components/layout/InputField';
import { commaRemover } from '../../../../utils/helper';
import { GET_COMPANY_AGREEMENTS_ON_PLACEMENT } from '../gql';
import { RejectionAlert } from '../view/RejectionAlert';
import ACAInfoCalculations from './ACAInfoCalculations';
import BasicDetailsStepOne from './BasicDetailsStepOne';
import ContractPlacementTypeBase from './ContractPlacementTypeBase';
import DirectHirePlacementTypeBase from './DirectHirePlacementTypeBase';
import RateInfoCalculations from './RateInfoCalculation';
import RecruiterInfoProfitCalculations from './RecruiterInfoProfitCalculations';

export default function EditPlacementStep({
  setValue,
  readonly,
  setError,
  companyName,
  watch,
  companyId,
  control,
  errors,
  salaryType,
  setSalaryType,
  register,
  setPlacementType,
  placementType,
  localSalary,
  setEndingDate,
  startDateString,
  setAssignedDuration,
  endingDate,
  assignedDuration,
  disableAllFields,
  placementId,
  approval,
  statusNote,
  rejectOrApproveBy,
  placementEndDate,
  estimatedLessPayrollBurdenPercenatge,
  setEditModal
}: any) {
  const [
    _getCompanyAgreements,
    { loading: _getCompanyAgreementsLoading, data: _getCompanyAgreementsData },
  ] = useLazyQuery(GET_COMPANY_AGREEMENTS_ON_PLACEMENT);

  const[estimateValue,setEstimateValue]=useState<number>(0)
  
  useEffect(() => {
    if (companyId) {
      _getCompanyAgreements({ variables: { companyId } });
    }
  }, [_getCompanyAgreements, companyId]);

useEffect(()=>{
  setEstimateValue(estimatedLessPayrollBurdenPercenatge? (estimatedLessPayrollBurdenPercenatge/100) * commaRemover(watch('payRate')):0.1366 * commaRemover(watch('payRate')))
},[estimatedLessPayrollBurdenPercenatge, watch])

  return (
    <div className="edit-detail-placement">
      <BasicDetailsStepOne
        disableAllFields={disableAllFields}
        readonly={readonly}
        setPlacementType={setPlacementType}
        control={control}
        register={register}
        errors={errors}
        placementId={placementId}
        placementEndDate={placementEndDate}
      />

      <Row>
        {placementType && placementType?.value === 'DIRECT_HIRE' ? (
          <>
            <DirectHirePlacementTypeBase
              disableAllFields={disableAllFields}
              readonly={readonly}
              watch={watch}
              _getCompanyAgreementsLoading={_getCompanyAgreementsLoading}
              localSalary={localSalary}
              agreements={
                _getCompanyAgreementsData?.getCompanyAgreementsPlacement
              }
              setSalaryType={setSalaryType}
              control={control}
              salaryType={salaryType}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </>
        ) : placementType?.value === 'CONTRACT' ? (
          <ContractPlacementTypeBase
            disableAllFields={disableAllFields}
            readonly={readonly}
            setValue={setValue}
            setError={setError}
            setEndingDate={setEndingDate}
            startDateString={startDateString}
            setAssignedDuration={setAssignedDuration}
            companyId={companyId}
            companyName={companyName}
            watch={watch}
            control={control}
            register={register}
            errors={errors}
            setEditModal= {setEditModal}
          />
        ) : null}
      </Row>

      <Row>
        {placementType && placementType?.value === 'CONTRACT' && (
          <Col md="4" sm="12" xs="12" className="forInputMargin">
            <Label>Approx End Date </Label>

            <Input
              disabled={true}
              value={
                endingDate === undefined
                  ? moment(startDateString)
                      .add(assignedDuration * 7, 'days')
                      .format('YYYY-MM-DD')
                  : moment(endingDate).format('YYYY-MM-DD')
              }
              readOnly={readonly}
              type="date"
              name="endDate"
              innerRef={register({
                required: {
                  value: false,
                  message: 'End date is required!',
                },
              })}
            />
            <small className="text-danger">{errors?.endDate?.message}</small>
          </Col>
        )}
      </Row>

      <div className="noteSection my-2 light-dark-color">
        <h5 className="shortTextWidth">NOTES SECTION</h5>
      </div>

      <Row className="mt-2">
        <Col
          lg="6"
          md="6"
          sm="12"
          className={
            placementType && placementType?.value === 'DIRECT_HIRE'
              ? 'mt-3'
              : ''
          }
        >
          <InputField
            readOnly={readonly}
            disabled={disableAllFields}
            label="Placement Notes"
            inputtype="textarea"
            placeholder="Placement Notes"
            inputid="placementNote"
            inputRef={register()}
          />
        </Col>

        <Col
          lg="6"
          md="6"
          sm="12"
          className={
            placementType && placementType?.value === 'DIRECT_HIRE'
              ? 'mt-3'
              : ''
          }
        >
          <InputField
            readOnly={readonly}
            disabled={disableAllFields}
            label="Notes to Accounting"
            inputtype="textarea"
            placeholder="Notes to Accounting"
            inputid="noteToAccounting"
            inputRef={register()}
          />
        </Col>
      </Row>
      {approval === false && (
        <RejectionAlert
          rejectedBy={rejectOrApproveBy}
          statusNote={statusNote}
        />
      )}
      {placementType && placementType?.value === 'CONTRACT' && (
        <>
          <RateInfoCalculations watch={watch} estimatedLessPayrollBurdenPercenatge={estimatedLessPayrollBurdenPercenatge} />
          <ACAInfoCalculations
            watch={watch}
            assignedDuration={assignedDuration}
          />
          <RecruiterInfoProfitCalculations
            assignedDuration={assignedDuration}
            recruiterPerHourRate={
              commaRemover(watch('billRate')) -
              commaRemover(watch('payRate')) -
              estimateValue-
              0.05 * commaRemover(watch('billRate'))
            }
          />
        </>
      )}
    </div>
  );
}
