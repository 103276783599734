// packages block
import { useLazyQuery } from "@apollo/client";
import classnames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Badge, Col, Dropdown,
  DropdownItem, DropdownMenu, DropdownToggle, Nav,
  NavItem,
  NavLink, Row, TabContent,
  TabPane, Tooltip
} from "reactstrap";
// component block
import ActionLogsAndNotes from "../../components/ActionLogsAndNotes";
import AddNotesModal from "../../components/AddNotesModal";
import GoBack from "../../components/GoBack";
import AlertCard from "../../components/layout/Alert";
import Loader from "../../components/layout/Loader";
import WidgetCard from "../../components/layout/WidgetCard";
import SocialMediaIcons from "../../components/SocialMediaIcons";
import ViewMoreSummaryModal from "../../components/ViewMoreSummaryModal";
import Attachments from "./company-components/Attachments";
import CompanyAddressCard from "./company-components/CompanyAddressCard";
import CompanyAgreement from "./company-components/CompanyAgreementCard";
import CompanyContactOnViewCompany from "./company-components/CompanyContactOnViewCompany";
import OwnerShipShare from "./company-components/OwnerShipShare";
// graphql, svgs, utils block
import LINK_ICON from "../../assets/images/link-icon.svg";
import READ_MORE_ARROW from "../../assets/images/read-arrow.svg";
import LINKEDIN_IMG from "../../assets/images/social1.svg";
import USER_ICON from "../../assets/images/user-icon.svg";
import { AppContext } from "../../context";
import { COMPANIES_ROUTE, COMPANY_DETAILS_ROUTE, JOB_ORDER_ROUTE, UPDATE_COMPANY_ROUTE } from "../../utils/constant";
import { countWordsInString, pushInHistory, trimStringUptoWords } from "../../utils/helper";
import { IComingState } from "../agreements/interfaces";
import "../company/company.scss";
import { FETCH_COMPANY_DETAILS, GET_COMPANY_STATS } from "./gql";

const ViewCompany = (props: any) => {
  const location = useLocation<IComingState>();
  const id = props.match.params.id;
  const history = useHistory();
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';
  const [activeTab, setActiveTab] = useState(activeParamTab);
  const comingState: any = location.state;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [cultureModal, setCultureModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [noteData, setNoteData] = useState(null);
  const [contacts, setContacts] = useState<any[]>([]);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const toggleActivityNotes = () => setActivityNotes(!activityNotes);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [getCompanyDetail, { data, loading }] = useLazyQuery(
    FETCH_COMPANY_DETAILS
  );

  const cultureToggle = () => setCultureModal(!cultureModal);
  const [summaryModal, setSummaryModal] = useState(false);

  const [getCompanyStats, { data: companyStats }] =
    useLazyQuery(GET_COMPANY_STATS);
  const { user, userRoles } = useContext(AppContext);

  const summaryToggle = () => setSummaryModal(!summaryModal);

  const setContactData = useCallback(async () => {
    setCompanyId(id);
    const response: any = await getCompanyDetail({
      variables: { companyId: id },
    });
    getCompanyStats({ variables: { id } });

    if (response?.data?.getCompany) {
      setContacts(response?.data?.getCompany?.companyContacts || []);
    }
  }, [getCompanyDetail, getCompanyStats, id]);

  useEffect(() => {
    id && setContactData();
  }, [id, setContactData]);

  useEffect(() => {
    document.title = loading
      ? 'Loading...'
      : `${data?.getCompany.name || ''} - Company - RealREPP`;

    // eslint-disable-next-line
  }, [data, loading]);

  let workPhone = data?.getCompany?.phones?.find(
    (item: { status: string; isPrimary: boolean }) =>
      item?.status === 'WORK' && item.isPrimary === true && item
  );

  let faxPhone = data?.getCompany?.phones?.find(
    (item: { status: string }) => item?.status === 'FAX' && item
  );

  if (loading) {
    return <Loader />;
  }

  //need todo code for getting the permission for sharee, same the api call as all company payload
  const checkEditPermission = (company: any) => {
    if (!!company) {
      if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
        return true;
      }

      let currentUser = company?.ownerAndSharees?.find(
        (item) => item.owner?.id === user?.id
      );
      if (currentUser) {
        return true;
      }

      if (!company?.ownerAndSharees?.length) {
        return true;
      }
    }

    return false;
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const companyPermission = checkEditPermission(data?.getCompany);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url={comingState?.backUrl ?? COMPANIES_ROUTE}
        search={comingState?.searchParameters}
      />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <div className="d-flex align-items-center">
          <h5 className="mr-2 mb-0 text-truncate max-200">{data?.getCompany?.name || "--"}</h5>

          <Badge
            color={
              data?.getCompany?.status === 'ACTIVE'
                ? 'success'
                : data?.getCompany?.status === 'PROSPECT'
                  ? 'warning'
                  : data?.getCompany?.status === 'DRAFT'
                    ? 'primary'
                    : data?.getCompany?.status === 'DO_NOT_CONTACT'
                      ? 'danger'
                      : data?.getCompany?.status === 'ACTIVE_AGREEMENT'
                        ? 'info'
                        : ''
            }
            className="text-capitalize"
          >
            {data?.getCompany?.status}
          </Badge>
        </div>

        {companyPermission && (
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="actionsDropdown"
          >
            <DropdownToggle caret>Actions</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag={Link}
                to={{
                  pathname: `${UPDATE_COMPANY_ROUTE}/${companyId}`,
                  state: {
                    backUrl: `${COMPANY_DETAILS_ROUTE}/${companyId}`,
                    searchParameters: location.search,
                  },
                }}
                onClick={() =>
                  pushInHistory(
                    `${COMPANY_DETAILS_ROUTE}/${companyId}`,
                    location.search
                  )
                }
                className="cursor-pointer m-0"
              >
                Edit
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>

      <div className="applicantDetail primaryHeaderSpacing">
        <div className="infoCardDivider">
          <a
            href={data?.getCompany?.linkedInUrl}
            target="_blank"
            className="m-0 d-flex"
            rel="noreferrer"
          >
            <Tooltip
              placement="top"
              isOpen={tooltipOpen1}
              target="TooltipExample1"
              toggle={toggle1}
            >
              Linkedin
            </Tooltip>
            <img
              src={LINKEDIN_IMG}
              alt="linkedin"
              width="17px"
              id="TooltipExample1"
            />
          </a>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen2}
            target="TooltipExample2"
            toggle={toggle2}
          >
            Company URL
          </Tooltip>

          <img
            src={LINK_ICON}
            alt="company"
            width="20px"
            id="TooltipExample2"
          />
          <label>
            <a
              href={data?.getCompany?.domain[0]?.domainUrl}
              target="_blank"
              rel="noreferrer"
              className="m-0"
            >
              {data?.getCompany?.domain[0]?.domainName || '--'}
            </a>
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen3}
            target="TooltipExample3"
            toggle={toggle3}
          >
            Company Owner
          </Tooltip>

          <img
            src={USER_ICON}
            alt="address"
            width="17px"
            id="TooltipExample3"
          />
          <label>
            <a
              href={data?.getCompany?.owner?.fullName}
              target="_blank"
              rel="noreferrer"
              className="m-0"
            >
              {data?.getCompany?.owner?.fullName || '--'}
            </a>
          </label>
        </div>
      </div>

      {data?.getCompany?.status === 'DO_NOT_CONTACT' && (
        <AlertCard
          title="Warning!"
          subTitle="Do not contact this company."
          color="danger"
        />
      )}

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              About
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Activities
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Owners & Sharee
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Attachment
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Address
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              Agreements
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
                toggleTab('7');
              }}
            >
              Company Contacts
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
                toggleTab('8');
              }}
            >
              Company Stats
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              target='_blank'
              className={classnames({ active: activeTab === "9" })}
              onClick={(e) => {
                e.preventDefault();
                const companyName = encodeURIComponent(data?.getCompany.name);
                const url = `${JOB_ORDER_ROUTE}?currentPage=1&selectedCompanyId=${companyId}&selectedCompanyName=${companyName}&statusFilter=ACTIVE`;
                window.open(url, "_blank");
              }}
            >
              Job Orders
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <Row>
              <Col lg="6" md="6" sm="12">
                <div>
                  <h5>Basic Info</h5>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Name</label>

                    <div className="contentGrid">
                      <h6 className="text-truncate max-200">{data?.getCompany?.name || "--"}</h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Phone</label>

                    <div className="contentGrid">
                      <h6>
                        <a href={`tel:${workPhone?.phoneNumber}`}>
                          {workPhone?.phoneNumber || '--'}
                        </a>
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Fax</label>

                    <div className="contentGrid">
                      <h6>
                        <a href={`tel:${faxPhone?.phoneNumber}`}>
                          {faxPhone?.phoneNumber || '--'}
                        </a>
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Career Page</label>

                    <div className="contentGrid">
                      <h6>
                        <a
                          href={data?.getCompany?.careerPageURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.getCompany?.careerPageURL || '--'}
                        </a>
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Company Size</label>

                    <div className="contentGrid">
                      <h6>{data?.getCompany?.size || '--'}</h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Company Url</label>

                    <div className="contentGrid">
                      <h6>
                        <a
                          href={data?.getCompany?.domain[0]?.domainUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.getCompany?.domain[0]?.domainName || '--'}
                        </a>
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Status</label>

                    <div className="contentGrid">
                      <h6>{data?.getCompany?.status}</h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-2 contentGridLabel">
                    <label>Non-Solicit</label>

                    <div className="contentGrid">
                      <h6>{data?.getCompany?.nonSolicit ? "Yes" : "No"}</h6>
                    </div>
                  </div>

                  <SocialMediaIcons
                    facebook={data?.getCompany?.fbUrl}
                    twitter={data?.getCompany?.twitterUrl}
                    linkedin={data?.getCompany?.linkedInUrl}
                    instagram={data?.getCompany?.instaUrl}
                  />
                </div>
              </Col>

              <Col lg="12" md="12" sm="12">
                <div className="summarySection m-0 mb-2">
                  <h5>Summary</h5>

                  {data?.getCompany?.summary &&
                    countWordsInString(data?.getCompany?.summary, 20) ? (
                    <>
                      <p className="companyTextWidth">
                        {trimStringUptoWords(
                          data?.getCompany?.summary?.replace(/<[^>]*>?/gm, ''),
                          30
                        )}
                      </p>
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={summaryToggle}
                      >
                        Read More <img src={READ_MORE_ARROW} alt="arrow" />
                      </button>
                    </>
                  ) : (
                    <p className="companyTextWidth">
                      {data?.getCompany?.summary?.replace(/<[^>]*>?/gm, '') ||
                        '--'}
                    </p>
                  )}
                </div>

                <div className="summarySection m-0 mb-2">
                  <h5>Company Culture</h5>

                  {data?.getCompany?.culture &&
                    countWordsInString(data?.getCompany?.culture, 20) ? (
                    <>
                      <p className="companyTextWidth">
                        {trimStringUptoWords(
                          data?.getCompany?.culture?.replace(/<[^>]*>?/gm, ''),
                          30
                        )}
                      </p>
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={cultureToggle}
                      >
                        Read More <img src={READ_MORE_ARROW} alt="arrow" />
                      </button>
                    </>
                  ) : (
                    <p className="companyTextWidth">
                      {data?.getCompany?.culture?.replace(/<[^>]*>?/gm, '') ||
                        '--'}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <div className="jobOrdersActionLog">
              <ActionLogsAndNotes
                haveActionPermissions={!companyPermission}
                option={{
                  companyId: data?.getCompany?.id ?? '',
                  companyName: data?.getCompany?.name ?? '',
                }}
                type="Company"
                recordId={companyId}
                viewDetail={false}
                companyContacts={contacts}
              />
            </div>
          </TabPane>

          <TabPane tabId="3">
            <OwnerShipShare
              sharingId={companyId}
              sharingType="company"
              isView={true}
            />
          </TabPane>

          <TabPane tabId="4">
            <Attachments
              haveActionPersmissions={!companyPermission}
              companyId={companyId}
              attachableType={'Company'}
            />
          </TabPane>

          <TabPane tabId="5">
            <CompanyAddressCard
              haveActionPermissions={!companyPermission}
              companyId={companyId}
            />
          </TabPane>

          <TabPane tabId="6">
            <CompanyAgreement
              companyDetails={{
                id: data?.getCompany?.id,
                name: data?.getCompany?.name,
              }}
            />
          </TabPane>

          <TabPane tabId="7">
            <CompanyContactOnViewCompany
              companyId={companyId}
              companyName={data?.getCompany?.name}
              haveActionPermissions={companyPermission}
            />
          </TabPane>

          <TabPane tabId="8" className="tab-pane-padding">
            <WidgetCard
              stats={companyStats?.getCompanyStats}
              sourceNote={data?.getCompany?.sourceNote}
            />
          </TabPane>
          <TabPane tabId="9" className="tab-pane-padding">
            {/* <CompanyJobOrders companyId={data?.getCompany?.id} /> */}
          </TabPane>
        </TabContent>
      </div>

      <AddNotesModal
        haveActionPersmissions={!companyPermission}
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={companyId}
        reFetch={() => { }}
        type="Company"
        editData={noteData}
        resetField={setNoteData}
      />

      <ViewMoreSummaryModal
        toggle={cultureToggle}
        isOpen={cultureModal}
        data={data?.getCompany?.culture}
        heading="Company Culture"
      />

      <ViewMoreSummaryModal
        toggle={summaryToggle}
        isOpen={summaryModal}
        data={data?.getCompany?.summary}
        heading="Company Summary"
      />
    </div>
  );
};

export default ViewCompany;