import { Controller } from "react-hook-form";
import Select from "react-select";
import { Col, Input, Label, Row } from "reactstrap";
import InputField from "../../../../components/layout/InputField";
import { PLACEMENT_TYPE } from "../../../../utils/constant";
import { AppContext } from "../../../../context";
import { useContext } from "react";

export default function BasicDetailsStepOne({
  control,
  setPlacementType,
  register,
  readonly,
  errors,
  disableAllFields,
  placementId,
  placementEndDate
}: any) {
  const { theme } = useContext(AppContext);
  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      backgroundColor: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      color: theme === "light" ? provided.backgroundColor : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Row>
      <Col lg="4" md="6" sm="12">
        <Label>Placement ID</Label>
        <Input
          readOnly={readonly}
          disabled={true}
          label="Placement ID"
          inputtype="text"
          defaultValue={placementId}
        
        />
      </Col>
      <Col lg="4" md="6" sm="12" className="agreementSelectSpacing">
        <Label>
          Start Date<span className="text-danger">*</span>
        </Label>
        <Input
          readOnly={readonly}
          disabled={disableAllFields}
          min={new Date().toISOString().substring(0, 10)}
          type="date"
          name="startDate"
          innerRef={register({
            required: {
              value: true,
              message: "Start date is required!",
            },
          })}
        />
        <small className="text-danger">{errors?.startDate?.message}</small>
      </Col>

      <Col lg="4" md="6" sm="12" className="agreementSelectSpacing">
        <Label>
          End Date<span className="text-danger">*</span>
        </Label>
        <Input
          readOnly={readonly}
          disabled={true}
          min={new Date().toISOString().substring(0, 10)}
          type="date"
          name="placementEndDate"
          innerRef={register()}
        />
        <small className="text-danger">{errors?.startDate?.message}</small>
      </Col>

      <Col lg="4" md="6" sm="12" className="displayNoneHeading">
        <Label>
          Placement Source
          <span className="text-danger">*</span>
        </Label>
        <InputField
          readOnly={readonly}
          disabled={disableAllFields}
          label=""
          isRequired
          inputtype="text"
          className="m-0"
          placeholder="Placement Source"
          inputid="placementSource"
          inputRef={register({
            required: {
              value: true,
              message: "Placement source is required!",
            },
          })}
        />

        <div className="d-flex flex-column">
          <small className="text-info">Example: “LinkedIn or Referral”.</small>
          <small className="text-danger">
            {errors?.placementSource?.message}
          </small>
        </div>
      </Col>

      <Col lg="4" md="6" sm="12" className="agreementSelectSpacing">
        <Label>
          Placement Type <span className="text-danger">*</span>
        </Label>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Placement type must be selected!",
            },
          }}
          name="placementType"
          render={({ onChange, name, value, ref }) => (
            <Select
              isDisabled={disableAllFields || readonly}
              onChange={(e) => {
                setPlacementType(e);
                onChange(e);
              }}
              value={value}
              name={name}
              ref={ref}
              options={PLACEMENT_TYPE?.map((item: any) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              styles={customStyles}
            />
          )}
          control={control}
        />
        <small className="text-danger">{errors?.placementType?.message}</small>
      </Col>
    </Row>
  );
}
