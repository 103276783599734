import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import Delete_Img from '../../../assets/images/candidate-delete.svg';
import EditImage from '../../../assets/images/edit.svg';
import UserImage from '../../../assets/images/user-empty.svg';
import visibility from '../../../assets/images/viewIcon.svg';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import ContentViewSidebar from '../../../components/ContentViewSidebar';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import { AppContext } from '../../../context';
import { formatDateUS } from '../../../utils/commonFn';
import { reactQuillReg, ROLES } from '../../../utils/constant';
import '../candidate.scss';
import {
  CHECK_WRITE_UP_STATE,
  CREATE_WRITE_UP,
  DELETE_WRITE_UP,
  EDIT_WRITE_UP,
  GET_CANDIDATE_SUMMARY,
  GET_CANDIDATE_WRITEUPS,
  REMOVE_OWNER,
} from '../gql';
import AddOwnerModal from './AddOwnerModal';

const CandidateOwnershipWriteup = ({
  candidateId,
  refetchCandidateWriteUp,
  refetchCandidateDetails
}: any) => {
  const page = parseInt('1');
  const [currentPage, setCurrentPage] = useState(page);
  const [limit] = useState(6);

  const [writeUpViewModel, setWriteUpViewModel] = useState(false);

  const [writeUpContent, setWriteUpContent] = useState('');
  const [confirmPopup, setConfirmPopUp] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [addWriteUp, setAddWriteUp] = useState(false);
  const [removeOwner] = useMutation(REMOVE_OWNER);

  const [editCheck, setEditCheck] = useState(false);
  const [writeUpId, setWriteUpId] = useState('');
  const [modal, setModal] = useState(false);
  const [ownerModal, setOwnerModal] = useState(false);
  const { userRoles, user } = useContext(AppContext);
  const [getWriteUps, { data, loading: writeUpLoad }] = useLazyQuery(
    GET_CANDIDATE_WRITEUPS
  );
  const [deleteWriteUp, { loading: deleteLoading }] =
    useMutation(DELETE_WRITE_UP);
  const [checkWriteUpState, { data: writeUps }] =
    useLazyQuery(CHECK_WRITE_UP_STATE);
  const [_createWriteUp, { loading }] = useMutation(CREATE_WRITE_UP);
  const [editWriteUp, { loading: updateLoading }] = useMutation(EDIT_WRITE_UP);
  const [getCandidateSummary, { data: dataSummary }] = useLazyQuery(
    GET_CANDIDATE_SUMMARY
  );
  const [writeupContent, setWriteupContent] = useState({
    content: '',
    title: { label: '', value: '' },
  });
  const modalToggle = () => {
    setModal(!modal);
    if (editCheck) {
      setEditCheck(false);
      setWriteupContent({
        content: '',
        title: {
          label: '',
          value: '',
        },
      });
    }
  };
  const [activeTab] = useState('1');

  const { errors, handleSubmit, control } = useForm();

  const createOwnerShipNote = async (values) => {
    let payload = {
      writeupSummary: values?.content,
      writeUpType: values?.writeUpType?.value,
      candidateId: candidateId,
    };
    const res = await _createWriteUp({ variables: { ...payload } });
    if (res?.data) {
      toast.success('Successfully Created!');
      modalToggle();
      _getWriteUps();
      checkWriteUpState({ variables: { id: candidateId } });
      _getCandidateSummary();
    }
  };

  const _getWriteUps = () => {
    getWriteUps({ variables: { id: candidateId, limit, page: currentPage } });
  };
  const _getCandidateSummary = () => {
    getCandidateSummary({ variables: { id: candidateId } });
  };

  const _deleteWriteUps = async () => {
    let res = await deleteWriteUp({ variables: { id: writeUpId } });
    if (res?.data) {
      setConfirmPopUp(false);
      _getWriteUps();
      checkWriteUpState({ variables: { id: candidateId } });
      toast.success('Successfully Deleted!');
    }
  };
  const editOwnerShipWriteup = async (values) => {
    const res = await editWriteUp({
      variables: {
        writeupId: writeUpId,
        writeupSummary: values?.content,
      },
    });
    if (res?.data) {
      _getWriteUps();
      modalToggle();
      toast.success('Successfully edited');
    } else {
      toast.error(
        'An error occured in processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (candidateId) {
      _getWriteUps();
      _getCandidateSummary();
      checkWriteUpState({ variables: { id: candidateId } });
    }

    // eslint-disable-next-line
  }, [candidateId, refetchCandidateWriteUp]);

  useEffect(() => {
    if (writeUps?.checkWritUpState) {
      let state = writeUps?.checkWritUpState;

      if (!state?.summary) {
        setAddWriteUp(true);
        return;
      }
      setAddWriteUp(false);
    }

    // eslint-disable-next-line
  }, [writeUps]);

  const checkAdminOrExec = () => {
    if (
      userRoles?.includes(ROLES.ADMIN) ||
      userRoles?.includes(ROLES.EXECUTIVE)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isUserCandidateOwner = () => {
    if (user?.id === dataSummary?.getCandidateSummary?.recruitingOwner?.id) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (activeTab === '1') {
      _getWriteUps();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const removeRecruiterOwner = async () => {
    if (dataSummary?.getCandidateSummary?.recruitingOwner?.id) {
      const res = await removeOwner({ variables: { candidateId } });
      if (res?.data) {
        setConfirmToggle(!confirmToggle);
        toast.success('Owner Removed Successfully');
        _getCandidateSummary();
        checkWriteUpState({ variables: { id: candidateId } });
      } else {
        toast.error('An error occured. Please try again later');
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     dataSummary?.getCandidateSummary?.recruitingOwner &&
  //     setCandidateOwner
  //   ) {
  //     setCandidateOwner(dataSummary?.getCandidateSummary?.recruitingOwner);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataSummary]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <>
      <div className="logs-tab">
        <Card className="border-0">
          <div className="candidate-action px-4 py-3">
            <div className="d-flex align-items-center candidateWriteUp">
              <div className="candidate-avatar">
                <img
                  src={
                    dataSummary?.getCandidateSummary?.recruitingOwner?.photo ||
                    UserImage
                  }
                  alt="avatar"
                  style={{ borderRadius: '10px' }}
                />
              </div>
              <div>
                {dataSummary?.getCandidateSummary?.recruitingOwner ? (
                  <h6 style={{ fontSize: '14px' }}>
                    <span>
                      {
                        dataSummary?.getCandidateSummary?.recruitingOwner
                          ?.fullName
                      }
                    </span>
                    {'  '}
                    <label style={{ fontSize: '14px', marginBottom: '2px' }}>
                      ( Candidate Owner )
                    </label>
                  </h6>
                ) : (
                  <label style={{ fontSize: '14px', marginBottom: '2px' }}>
                    Candidate Owner
                  </label>
                )}{' '}
                <h6 style={{ fontSize: '14px' }}>
                  {' '}
                  {(checkAdminOrExec() || isUserCandidateOwner()) &&
                    (dataSummary?.getCandidateSummary?.ownershipExpiration ? (
                      <div className="mt-1">
                        Ownership Expiration:{' '}
                        {formatDateUS(
                          dataSummary?.getCandidateSummary?.ownershipExpiration
                        )}
                      </div>
                    ) : null)}
                </h6>{' '}
                {checkAdminOrExec() &&
                  (!dataSummary?.getCandidateSummary?.recruitingOwner ? (
                    <div
                      onClick={() => setOwnerModal(!ownerModal)}
                      style={{
                        fontSize: '12px',
                        color: '#eaa827',
                        cursor: 'pointer',
                      }}
                      className="mt-1"
                    >
                      Add Owner
                    </div>
                  ) : (
                    <span
                      style={{
                        color: '#eaa827',
                        cursor: 'pointer',
                        display: 'inline-block',
                        fontSize: '12px',
                      }}
                      className="mt-1"
                      onClick={() => setConfirmToggle(!confirmToggle)}
                    >
                      Remove Owner
                    </span>
                  ))}
              </div>
            </div>
            {dataSummary?.getCandidateSummary?.recruitingOwner?.id ===
              user?.id &&
              addWriteUp && (
                <Button
                  type="button"
                  className="btn btn-secondary"
                  onClick={modalToggle}
                  style={{ fontSize: '14px', marginBottom: '8px' }}
                  disabled={!addWriteUp}
                >
                  Add New Writeup
                </Button>
              )}
          </div>
          {data?.getWriteUps?.writeUps?.map((item) => (
            <div className="candidate-notes-wrapper pt-2 pb-0">
              {dataSummary?.getCandidateSummary?.recruitingOwner?.id ?
                <div className="candidate-notes p-0 pb-2">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <label className="ownerNotesWidth text-truncate d-block">
                      {item?.writeupSummary
                        .split(/\s+/)
                        .slice(0, 20)
                        .join(' ')
                        .replace(/<[^>]*>?/gm, ' ')}
                    </label>
                    <div>
                      {((isUserCandidateOwner() &&
                        item.endedAt === null &&
                        user?.id === item?.user?.id) ||
                        checkAdminOrExec()) && (
                          <Button
                            type="button"
                            className="bg-transparent border-0 btn p-0 mr-3"
                            onClick={() => {
                              setEditCheck(true);
                              setWriteupContent({
                                content: item?.writeupSummary,
                                title: {
                                  label: 'Complete Writeup',
                                  value: 'SUMMARY',
                                },
                              });
                              setWriteUpId(item.id);
                              modalToggle();
                            }}
                          >
                            <img
                              src={EditImage}
                              alt="Edit"
                              className="m-0"
                              width="16"
                            />
                          </Button>
                        )}

                      <Button
                        type="button"
                        className="bg-transparent border-0 btn p-0 mr-3"
                        onClick={() => {
                          setWriteUpContent(item?.writeupSummary);
                          setWriteUpViewModel(!writeUpViewModel);
                        }}
                      >
                        <img
                          src={visibility}
                          alt="Edit"
                          className="m-0"
                          width="20"
                        />
                      </Button>

                      {checkAdminOrExec() && (
                        <Button
                          type="button"
                          className="bg-transparent border-0 p-0"
                          onClick={() => {
                            setConfirmPopUp(!confirmPopup);
                            setWriteUpId(item.id);
                          }}
                        >
                          <img src={Delete_Img} alt="delete" className="m-0" />
                        </Button>
                      )}
                    </div>
                  </div>
                  {/* <div className="d-flex">
                        <ItemSummary item={item} from={""} />
                      </div> */}
                  <h6 className="m-0">
                    {/* <img src={Eclipse_Img} alt="abc" /> */}
                    <span>
                      {item?.createdAt ? formatDateUS(item?.createdAt) : '--'}{' '}
                      {/* {item?.createdAt &&
                            new Date(item.createdAt).toLocaleTimeString()}{" "} */}
                    </span>{' '}
                    <span style={{ paddingLeft: '5px' }}>
                      {' '}
                      {item?.user?.fullName}
                    </span>
                  </h6>
                </div> : null}

            </div>
          ))}

          {!writeUpLoad && !data?.getWriteUps?.writeUps?.length && (
            <div className="empty-table-td">
              <NoDataFound text="No Write Ups Found!" />
            </div>
          )}
          {data?.getWriteUps?.writeUps?.length ? (
            <div className="d-flex justify-content-end users-pagination dark-users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setCurrentPage(pageClicked);
                }}
                pageCount={Math.ceil(data?.getWriteUps?.count / limit)}
                currentPage={currentPage}
              />
            </div>
          ) : (
            ''
          )}
        </Card>
      </div>

      <Modal
        isOpen={modal}
        toggle={modalToggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={modalToggle}>
          {editCheck ? 'Update Candidate Writeup' : 'Add Candidate Writeup'}
        </ModalHeader>
        <ModalBody className="pb-4">
          <Form>
            <Card className="border-0">
              <CardBody className="p-0">
                {/* <Label>
                  {editCheck
                    ? "Update Candidate Writeup"
                    : "Add Candidate Writeup"}
                </Label> */}
                <FormGroup>
                  <Label>Write Up Type</Label>
                  <Controller
                    name="writeUpType"
                    as={Select}
                    options={{ label: 'Complete Writeup', value: 'SUMMARY' }}
                    control={control}
                    defaultValue={{
                      label: 'Complete Writeup',
                      value: 'SUMMARY',
                    }}
                    placeholder="Write Up Type"
                    isSearchable={false}
                    isDisabled={true}
                    styles={customStyles}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Notes</Label>
                  <Controller
                    control={control}
                    name="content"
                    rules={{ required: 'Please add some content!' }}
                    defaultValue={editCheck ? writeupContent?.content : ''}
                    render={({ onChange, ref, value = '' }) => (
                      <div className="editor-height">
                        <ReactQuill
                          onChange={(value) => {
                            if (reactQuillReg.test(value)) {
                              onChange('');
                            } else {
                              onChange(value);
                            }
                          }}
                          value={value}
                          defaultValue={
                            editCheck ? writeupContent?.content : ''
                          }
                          ref={ref}
                        />
                      </div>
                    )}
                    styles={customStyles}
                  />
                </FormGroup>
                <small className="text-danger">{errors?.note?.message}</small>
                <div className="justify-content-end align-items-center d-flex pt-2">
                  <button
                    type="button"
                    onClick={modalToggle}
                    className="buttonPrimary mr-3"
                  >
                    Cancel
                  </button>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={loading || updateLoading}
                    onClick={
                      editCheck
                        ? handleSubmit(editOwnerShipWriteup)
                        : handleSubmit(createOwnerShipNote)
                    }
                  >
                    {(loading || updateLoading) && (
                      <Spinner
                        size="sm"
                        style={{ color: '#eaa827' }}
                        className="mr-2"
                      />
                    )}
                    {editCheck ? 'Update' : 'Create'}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </ModalBody>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this writeup?"
        isOpen={confirmPopup}
        toggle={setConfirmPopUp}
        confirmAction={_deleteWriteUps}
        modalHeading="Delete Writeup"
        btnText="Delete"
        btnColor="primary"
        loading={deleteLoading}
        disabled={deleteLoading}
        className="revampDialog revampDialogWidth"
      />

      <AddOwnerModal
        updateUI={_getCandidateSummary}
        candidateId={candidateId}
        ownerModal={ownerModal}
        setOwnerModal={setOwnerModal}
        refetchCandidateDetails={refetchCandidateDetails}
      />

      <ContentViewSidebar
        toggle={() => setWriteUpViewModel(!writeUpViewModel)}
        content={writeUpContent}
        isOpen={writeUpViewModel}
        heading={'Candidate Writeup'}
        emailHeader={null}

      />

      <ConfirmPopUp
        confirmText="Are you sure you want to remove this recruiter?"
        isOpen={confirmToggle}
        toggle={() => {
          setConfirmToggle(!confirmToggle);
        }}
        confirmAction={removeRecruiterOwner}
        modalHeading="Remove Recruiter"
        btnText="Remove"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </>
  );
};

export default withRouter(CandidateOwnershipWriteup);
