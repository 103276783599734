export const customSelectDropDown = (theme) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === 'light' ? provided.background : '#282828',
    borderColor: theme === 'light' ? '#ced4da' : '#525252',
    color: theme === 'light' ? provided.background : '#d9d9d9',
    fontSize: 12,
    // minHeight : 50,
    '&:hover': {
      borderColor: theme === 'light' ? '#ced4da' : '#525252',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: theme === 'light' ? provided.background : '#282828',
    color: theme === 'light' ? provided.background : '#d9d9d9',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === 'light' ? provided.background : '#363636',
    '&:hover': {
      background: theme === 'light' ? provided.background : '#474444',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
    innerHeight: 40,
  }),
});

export const customSelectStyleNew = (theme) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === "light" ? provided.background : "#282828",
    borderColor: theme === "light" ? "#ced4da" : "#525252",
    color: theme === "light" ? provided.background : "#d9d9d9",
    fontSize: 12,
    "&:hover": {
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      color: theme === "light" ? provided.background : "#d9d9d9",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: theme === "light" ? provided.background : "#282828",
    zIndex: 999999,
    color: theme === "light" ? provided.background : "#d9d9d9",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === "light" ? provided.background : "#363636",
    "&:hover": {
      background: theme === "light" ? provided.background : "#474444",
      color: theme === "light" ? provided.background : "#d9d9d9",
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
  }),
});

export const customSelectDropDownMin = (theme, height) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === 'light' ? provided.background : '#282828',
    borderColor: theme === 'light' ? '#ced4da' : '#525252',
    color: theme === 'light' ? provided.background : '#d9d9d9',
    fontSize: 12,
    minHeight: height,
    '&:hover': {
      borderColor: theme === 'light' ? '#ced4da' : '#525252',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: theme === 'light' ? provided.background : '#282828',
    color: theme === 'light' ? provided.background : '#d9d9d9',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === 'light' ? provided.background : '#363636',
    '&:hover': {
      background: theme === 'light' ? provided.background : '#474444',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
    innerHeight: 40,
  }),
});
