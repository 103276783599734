const AssignmentInformation = ({ assignmentInfo }) => {
  return (
    <div className="jobOrderRevamp">
      <h5>
        <span>Assignment Information</span>
      </h5>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Placement ID</label>
        <h6>{assignmentInfo?.placementId}</h6>
      </div>
      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Job Title</label>
        <h6>{assignmentInfo?.jobTitle}</h6>
      </div>
      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Workplace Type</label>
        <h6>{assignmentInfo?.workplaceType || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Start Date</label>
        <h6>{assignmentInfo?.startDate || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Approx End Date</label>
        <h6>
          {" "}
          {assignmentInfo?.estimateEndDate
            ? assignmentInfo?.estimateEndDate
            : "--"}
        </h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Number of weeks</label>
        <h6>{assignmentInfo?.assignmentDuration}</h6>
      </div>
    </div>
  );
};

export default AssignmentInformation;
