import { useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import Select from 'react-select';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import InputField from '../../../components/layout/InputField';
import { customSelectDropDown } from '../../../components/styled/customSelect';
import {
  CompanySize,
  CompanySource,
  COMPANY_STATUS,
  reactQuillReg,
  ROLES,
  URL_REGEX,
} from '../../../utils/constant';
import { FETCH_INDUSTRIES } from '../gql';

import '../../company/company.scss';
import { AppContext } from '../../../context';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { countSummaryWords } from '../../../utils/helper';

const BasicInfo = ({
  register,
  errors,
  unregister,
  value,
  setValue,
  getValues,
  control,
  ...props
}: any) => {
  const { theme } = useContext(AppContext);
  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const { data: industries } = useQuery(FETCH_INDUSTRIES);
  const { userRoles } = useContext(AppContext);
  const [status, setStatus] = useState(props?.companyStatus);
  const [hasCarerPage, setHasCarerPage] = useState(props?.hasCareerPageURL);
  const [hasCompanyURL, setHasCompanyURL] = useState(props?.hasCompanyURL);
  const [nonSolicit, setNonSolicit] = useState<boolean>(false)

  const industriesList = industries?.allIndustries?.length
    ? industries?.allIndustries?.map((item) => ({
      label: item.name,
      value: item.name,
    }))
    : [];

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div
      className="user-basic-form"
      onClick={() => {
        if (isSelectToggle) {
          setIsSelectToggle(!isSelectToggle);
        }
      }}
    >
      <Row>
        <Col xl="3" lg="4" md="6" sm="12">
          <InputField
            inputtype="text"
            isRequired={true}
            label="Name"
            inputid="name"
            placeholder="Name"
            maxLength={50}
            inputRef={register({
              required: {
                value: true,
                message: 'Name is required!',
              },
            })}
            errMsg={errors?.name?.message}
          />
        </Col>
        <Col xl="3" lg="4" md="6" sm="12">
          <InputField
            inputtype="text"
            label="Corporate Phone"
            inputid="phoneNumber"
            placeholder="Corporate Phone"
            inputRef={register({
              required: {
                value: false,
                message: 'Corporate phone is required',
              },
              // pattern: {
              //   value: /^\d*(\.\d+)?$/,
              //   message: "Corporate phone must be a number",
              // },
            })}
            errMsg={errors?.phoneNumber?.message}
          />
        </Col>

        <Col xl="3" lg="4" md="6" sm="12">
          <InputField
            inputtype="text"
            label="Fax"
            inputid="faxNumber"
            placeholder="Fax"
            inputRef={register({
              required: {
                value: false,
                message: 'Faxis is required!',
              },
            })}
            errMsg={errors?.faxNumber?.message}
          />
        </Col>

        <Col xl="3" lg="4" md="6" sm="12">
          <Label>
            Career Page URL{' '}
            {!hasCarerPage && <span className="text-danger">*</span>}{' '}
          </Label>
          <Input
            type="text"
            name="careerPageURL"
            placeholder="Career Page URL"
            innerRef={register({
              required: {
                value: hasCarerPage ? false : true,
                message: 'Career page URL is required!',
              },
              pattern: {
                value: URL_REGEX,
                message: 'Career page URL is not valid!',
              },
            })}
            defaultChecked={true}
            disabled={hasCarerPage}
          />

          {!hasCarerPage && (
            <small className="text-danger">
              {errors?.careerPageURL?.message}
            </small>
          )}

          <FormGroup className="ml-3 mb-1 mt-1 pl-1">
            <Label className="m-0 w-100">
              <Input
                type="checkbox"
                innerRef={register()}
                name="hasCareerPageURL"
                onChange={(e) => {
                  setValue('careerPageURL', null);
                  setHasCarerPage(e.target.checked);
                }}
                // disabled={hasCarerPage}
                className="mt-1"
              />
              <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                Has No Career Page Url
              </span>
            </Label>
          </FormGroup>
        </Col>

        <Col xl="3" lg="4" md="6" sm="12">
          <Label>Industry</Label>
          <Select
            isMulti
            value={props?.selectedIndustry}
            name="industry"
            options={industriesList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={props.setSelectedIndustry}
            styles={{ ...customSelectDropDown(theme) }}
          />
        </Col>

        <Col xl="3" lg="4" md="6" sm="12">
          <InputField
            label="Company Size"
            inputtype="select"
            selectItems={CompanySize}
            inputid="size"
            placeholder="Company Size"
            inputRef={register({ required: false })}
          // inputMethod={setCompanySize}
          />
        </Col>

        <Col xl="3" lg="4" md="6" sm="12">
          {userRoles && userRoles.length && userRoles.includes(ROLES.ADMIN) ? (
            <InputField
              label="Status"
              inputtype="select"
              selectItems={COMPANY_STATUS}
              inputid="status"
              placeholder="Status"
              inputRef={register({ required: false })}
              // inputValue={status}
              inputMethod={
                props?.recordId && !userRoles?.includes('ADMIN')
                  ? () => { }
                  : setStatus
              }
            />
          ) : (
            <InputField
              label="Status"
              inputtype="text"
              inputid="status"
              placeholder="Status"
              inputRef={register({ required: false })}
              inputValue={status}
              readOnly
            />
          )}
        </Col>

        {/* <Col xl="3" lg="4" md="6" sm="12">
          <InputField
            inputtype="text"
            label="Company Url"
            inputid="domain"
            isRequired
            placeholder="Company domain"
            inputRef={register({
              required: {
                value: true,
                message: "Company domain is required!",
              },
              pattern: {
                value: URL_REGEX,
                message: "Company domain is not valid!",
              },
            })}
            errMsg={errors?.domain?.message}
          />
        </Col> */}
        <Col xl="3" lg="4" md="6" sm="12">
          <Label>
            Company Url{' '}
            {!hasCompanyURL && <span className="text-danger">*</span>}{' '}
          </Label>
          <Input
            type="text"
            name="domain"
            placeholder="Company domain"
            innerRef={register({
              required: {
                value: hasCompanyURL ? false : true,
                message: 'Company domain is required!',
              },
              pattern: {
                value: URL_REGEX,
                message: 'Company domain is not valid!',
              },
            })}
            defaultChecked={true}
            disabled={hasCompanyURL}
          />

          {!hasCompanyURL && (
            <small className="text-danger">{errors?.domain?.message}</small>
          )}

          <FormGroup className="ml-3 mb-1 mt-1 pl-1">
            <Label className="m-0 w-100">
              <Input
                type="checkbox"
                innerRef={register()}
                name="hasCompanyURL"
                onChange={(e) => {
                  setValue('domain', null);
                  setHasCompanyURL(e.target.checked);
                }}
                // disabled={hasCarerPage}
                className="mt-1"
              />
              <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                Has No Company Url
              </span>
            </Label>
          </FormGroup>
        </Col>

        {props?.recordId && (
          <>
            <Col md="12">
              <FormGroup>
                <Label>Company Culture</Label>
                <Controller
                  control={control}
                  name="culture"
                  // rules={{ required: "Content is required!" }}
                  defaultValue={''}
                  render={({ onChange, ref, value = '' }) => (
                    <div className="editor-height">
                      <ReactQuill
                        onChange={(value) => {
                          if (reactQuillReg.test(value)) {
                            onChange('');
                          } else {
                            onChange(value);
                          }
                        }}
                        value={value}
                        ref={ref}
                      />
                    </div>
                  )}
                  styles={customStyles}
                />
                <small className="text-danger pt-2">
                  {errors?.culture?.message}
                </small>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label>
                  Company Summary <span className="text-danger">*</span>
                </Label>
                <Controller
                  control={control}
                  name="summary"
                  // rules={{
                  //   required: false,
                  //   minLength: {
                  //     value: 10,
                  //     message: "Minimum 10 words required!",
                  //   },
                  // }}
                  defaultValue={''}
                  render={({ onChange, ref, value = '' }) => (
                    <div className="editor-height">
                      <ReactQuill
                        onChange={(value: any) => {
                          if (reactQuillReg.test(value)) {
                            onChange('');
                            props?.setSummaryErr('Summary field is required!');
                          } else {
                            onChange(value);
                            if (countSummaryWords(value) >= 10) {
                              props?.setSummaryErr('');
                            }
                            if (countSummaryWords(value) < 10) {
                              props?.setSummaryErr(
                                'Summary must has at least 10 words!'
                              );
                              return;
                            }
                          }
                        }}
                        value={value}
                        ref={ref}
                      />
                    </div>
                  )}
                  styles={customStyles}
                />
                <small className="text-danger">{props?.summaryErr}</small>
              </FormGroup>
            </Col>
          </>
        )}
        <Col xl="3" lg="4" md="6" sm="12">
          {/* {props?.recordId ? (
            <>
              <InputField
                inputtype="text"
                label="Source"
                isRequired={true}
                inputid="source"
                value={props?.companySource}
                placeholder="Source"
                readOnly={true}
              />
              <div className="error">{errors?.source?.message}</div>
            </>
          ) : ( */}

          {!props?.recordId && (
            <InputField
              label="Source"
              isRequired={true}
              inputtype="select"
              selectItems={CompanySource}
              inputid="source"
              placeholder="Source"
              inputValue={props?.companySource}
              inputRef={register({
                required: { value: true, message: 'Source is required!' },
              })}
              readOnly={props?.recordId ? true : false}
              errMsg={errors?.source?.message}
            />
          )}

          {/* )} */}
        </Col>

        <Col xl="3" lg="4" md="6" sm="12">
          {!props?.recordId && (
            <FormGroup>
              <Label>
                Source Note <span className="text-danger">*</span>
              </Label>
              <input
                name="sourceNote"
                ref={register({
                  required: {
                    value: true,
                    message: 'Source note is required!',
                  },
                })}
                placeholder="Source Note..."
                // rows={2}
                className="form-control"
                readOnly={props?.recordId ? true : false}
              />
              <div className="error">{errors?.sourceNote?.message}</div>
            </FormGroup>
          )}
        </Col>

        {!props?.recordId &&
          <Col lg="4" sm="12" xs="12">
            <FormGroup>
              <Label for="preferredContact">Non-Solicit</Label>
              <div className="d-flex items-center" style={{ marginTop: '5px' }}>
                <Label className="radio-container">
                  Non-Solicit
                  <input
                    type="checkbox"
                    checked={nonSolicit}
                    ref={register()}
                    name="nonSolicit"
                    onChange={(e) => {
                      setNonSolicit(e.target.checked);
                    }}
                  //       className="mt-1"
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </FormGroup>
          </Col>
        }
      </Row>
    </div>
  );
};

export default BasicInfo;
