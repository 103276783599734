import { useLazyQuery, useMutation } from '@apollo/client';
import classnames from 'classnames';
import { Key, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import AvatarImage from '../../assets/images/user-empty.svg';
import CustomButton from '../../components/layout/CustomButton';
import InputField from '../../components/layout/InputField';
import Loader from '../../components/layout/Loader';
import UploadImage from '../../components/layout/UploadImage.tsx';
import { ROLES, SETTINGS_ROUTE, URL_REGEX, bucketFolders } from '../../utils/constant';
import UpdatePasswordComponent from './UpdatePassword';
import {
  FETCH_CURRENT_USER,
  OUTLOOK_LOGIN,
  OUTLOOK_LOGOUT,
  USER_UPDATE,
} from './gql';

// Styles
import { Link, useHistory, useLocation } from 'react-router-dom';
import AlertCard from '../../components/layout/Alert';
import { GOAL_SHEET_REPORT, TRAINING_VIDEOS, USER_ACTIVITY_REPORT } from '../../constants';
import { AppContext } from '../../context';
import { outlookAccountingLink, outlookLink } from '../../utils/Temp-Helper';
import { formatDateUS } from '../../utils/commonFn';
import {
  DocusignExecutiveEmail,
  docuSignRedirectURI,
  docusignIKey,
  docusignURLForCode,
  uploadImage,
} from '../../utils/helper';
import Reports from '../Reports/Reports';
import { FETCH_USER_IMPERSONATE } from '../admin/gql';
import { IComingState } from '../agreements/interfaces';
import { FETACH_ALL_ALERTS_OR_WARNINGS } from '../alert-warnings/gql';
import TjLookupSetting from '../interseller/tjlookup-setting';
import OpenGoalSheet from './OpenGoalSheet';
import RealReppDocuments from './RealReppDocument';
import './style.scss';
import TjTalVideo from './tjTalkVideo/TjLookupVideo';

interface SettingsFormPayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone: string;
  phone?: string;
  linkedInURL?: string;
  jobTitle?: string;
  bio?: string;
  quote?: string;
  linkedinURL?: string;
  photo?: any;
}

const SettingsComponent = () => {
  const history = useHistory();
  const location = useLocation<IComingState>();
  const [currentUserDetail, { data, loading }] =
    useLazyQuery(FETCH_CURRENT_USER);
  const [profileImage, setProfileImage] = useState<any>();
  const [previewURL, setPreviewURL] = useState<any>();
  const [updateUser, { loading: waiting }] = useMutation(USER_UPDATE);
  const { setContextUser, user } = useContext(AppContext);
  const [recruiterImpersonate, setRecruiterImpersonate] = useState(false);
  const [limit] = useState(10);
  const [page] = useState<number>(1);

  const [fetchAllAlertsOrWarnings, { data: warningData }] = useLazyQuery(
    FETACH_ALL_ALERTS_OR_WARNINGS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',

      notifyOnNetworkStatusChange: true,
      onError({ message }) {
        console.log('Notifications error =>', message);
      },
    }
  );

  const warningsHandler = useCallback(async (filter?: { name?: string }) => {
    await fetchAllAlertsOrWarnings({
      variables: {
        getAllSystemAlertsInput: {
          limit,
          page: page,
          filter
        }
      },
    });

  }, [fetchAllAlertsOrWarnings, limit, page])

  const messageFormat = (name: string, expirationData: string | Date): string => {
    if (!(expirationData instanceof Date)) {
      return `${name} would be expired on ${formatDateUS(new Date(parseInt(expirationData, 10)))}`;
    }
    return `${name} would be expired on ${expirationData} `;
  }

  useEffect(() => {
    warningsHandler()
  }, [warningsHandler])

  // const [tjlookupOpen, setTjlookupOpen] = useState(false);
  // const [selectedMenu, setSelectedMenu] = useState<string>();
  // const dropdownRef = useRef(null);

  const [outLookLogin, { data: outLook, loading: outlookLoading }] =
    useLazyQuery(OUTLOOK_LOGIN);
  const [outLookLogout] = useMutation(OUTLOOK_LOGOUT);

  const [impersonation, { data: impersonate }] = useLazyQuery(
    FETCH_USER_IMPERSONATE,
    {
      variables: {
        limit: 2,
        page: 1,
      },
    }
  );

  // const toggle = () => setTjlookupOpen((prevState) => !prevState);

  const [activeTab, setActiveTab] = useState<string>(new URLSearchParams(location.search).get('activeTab') || '1');

  const { register, handleSubmit, errors, reset } =
    useForm<SettingsFormPayload>();

  const { userRoles } = useContext(AppContext);

  const _updateUser = async (values: SettingsFormPayload) => {
    try {
      if (profileImage) {
        let response = await uploadImage(
          profileImage,
          bucketFolders.user,
          false,
          false,
          true
        );
        if (!response.success && !response?.ok) {
          toast.error(response?.message || 'Error while uploading image');
          setProfileImage(null);
          setPreviewURL(null);
          return;
        }
        if (response.success) {
          Object.assign(values, { photo: response.source });
        }
      }
      await updateUser({ variables: { userInput: { ...values } } });
      currentUserDetail();
      toast.success('Updated successfully!');
    } catch (error: any) {
      toast.error(error?.message || 'Error while updating profile.');
    }
  };

  useEffect(() => {
    document.title = `Settings - ${data?.me?.user?.firstName} - RealREPP`;

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data?.me?.user) {
      setContextUser(data?.me?.user || null);
      const { earningGoal, ...rest } = data.me.user
      reset({ ...data.me.user });
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    currentUserDetail();
    const code = new URLSearchParams(history?.location?.search).get('code') || '';
    const state = new URLSearchParams(history?.location?.search).get('state') || '';
    if (code) {
      outLookLogin({
        variables: {
          code,
          ...(state === '00253' && { accounting: true })
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const state = new URLSearchParams(history?.location?.search).get('state') || '';
    if (outLook?.gettingOutLookLogin?.status === 200) {
      currentUserDetail();
      history.push({ pathname: SETTINGS_ROUTE });
      toggleTab('4');
    }
    if (outLook?.gettingOutLookLogin?.status === 300 && state === '00253') {
      history.push({ pathname: SETTINGS_ROUTE });
      toggleTab('4');
      toast.error("accounts email is not correct")
    }

    // eslint-disable-next-line
  }, [outLook]);

  useEffect(() => {
    if (
      userRoles?.length &&
      !userRoles?.includes(ROLES.MANAGER) &&
      !userRoles?.includes(ROLES.EXECUTIVE) &&
      !userRoles?.includes(ROLES.ADMIN)
    ) {
      impersonation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles]);

  useEffect(() => {
    if (impersonate?.allImpersonateUsers?.users?.length) {
      setRecruiterImpersonate(true);
    }
  }, [impersonate]);

  const logoutOutlook = async (accounting?: boolean) => {
    let res = await outLookLogout({ variables: { id: user?.id, accounting } });
    if (res?.data) {
      toast.info('Logout Successfully');
      currentUserDetail();
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  if (loading || outlookLoading) {
    return <Loader />;
  }
  //
  return (
    <>
      {
        userRoles?.length &&
          (
            userRoles?.includes(ROLES.ADMIN) ||
            userRoles?.includes(ROLES.EXECUTIVE)
          ) ?
          warningData?.getAllSystemAlerts?.systemAlerts?.map(
            (
              item: {
                id: string;
                name: string;
                message: string;
                expirationDate: string;
                createdAt: string;
              },
              index: Key
            ) => {
              return (
                <AlertCard
                  title="Warning!"
                  subTitle={messageFormat(item.name, item.expirationDate)}
                  color="danger"
                />
              );
            }
          ) : ''
      }


      <div className="primaryHeading">
        <h5 className="m-0">Settings</h5>
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              Personal Details
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Update Password
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              RealREPP Documents
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              External Accounts
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              Reports
            </NavLink>
          </NavItem>

          <NavItem>
            <Link to="/notifications" className="nav-link">
              Notifications
            </Link>
          </NavItem>

          {userRoles?.length &&
            (userRoles?.includes(ROLES.ADMIN) ||
              userRoles?.includes(ROLES.EXECUTIVE) ||
              userRoles?.includes(ROLES.MANAGER) ||
              recruiterImpersonate) ? (
            <NavItem>
              <Link to="/admin/persist" className="nav-link">
                Impersonate
              </Link>
            </NavItem>
          ) : null}

          {userRoles?.length &&
            (userRoles?.includes(ROLES.ADMIN) ||
              userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE)) ? (
            <NavItem>
              <Link to="/admin" className="nav-link">
                Admin
              </Link>
            </NavItem>
          ) : (
            ''
          )}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              {TRAINING_VIDEOS}
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
                toggleTab('7');
              }}
            >
              {TJ_LOOKUP_SETTING}
            </NavLink>
          </NavItem> */}

          {/* <NavItem>
            <Link to="/notifications" className="nav-link">
              Notifications
            </Link>
          </NavItem> */}

          {/* <NavItem>
            <Link to={TJ_LOOKUP_SETTING_ROUTE} className="nav-link">
              {TJ_LOOKUP_SETTING}
            </Link>
          </NavItem> */}

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
                toggleTab('8');
              }}
            >
              {GOAL_SHEET_REPORT}
            </NavLink>
          </NavItem>


          {/** previous dropdown version */}
          {/* <NavItem>
            <NavLink className={classnames({ active: activeTab === '7' })}>
              <Dropdown
                isOpen={tjlookupOpen}
                toggle={toggle}
                innerRef={dropdownRef}
              >
                <DropdownToggle className="bg-transparent border-0 p-0 icon-div">
                  {selectedMenu ? (
                    <>
                      <span className="settings-dropdown-menu mr-2">
                        {selectedMenu}
                      </span>
                      {<DownArrowIcon />}
                    </>
                  ) : (
                    <span>{<DownArrowIcon />}</span>
                  )}
                </DropdownToggle>

                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      fn: (data) => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "100vh",
                          },
                        };
                      },
                    },
                  }}
                  container={document.body}
                  target={() => dropdownRef.current}
                  isOpen={tjlookupOpen}
                  toggle={toggle}
                >
                  <DropdownItem
                    className="settings-dropdown-menu"
                    onClick={() => {
                      setSelectedMenu(TJ_TALK_VIDEO);
                      toggleTab('5');
                    }}
                  >
                    {TJ_TALK_VIDEO}
                  </DropdownItem>

                  <DropdownItem className="settings-dropdown-menu">
                    <Link to={TJ_LOOKUP_SETTING_ROUTE}>
                      {TJ_LOOKUP_SETTING}
                    </Link>
                  </DropdownItem>

                 
                </DropdownMenu>
              </Dropdown>
            </NavLink>
          </NavItem> */}
          {/***/}

        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="tab-pane-padding">
            <UploadImage
              setImage={setProfileImage}
              setPreviewURL={setPreviewURL}
              src={previewURL || data?.me?.user?.photo || AvatarImage}
            />
            <div className="user-basic-form">
              <Form onSubmit={handleSubmit(_updateUser)}>
                <Row>
                  <Col xl="3" lg="4" md="6" xs="12">
                    <InputField
                      label="First Name"
                      isRequired
                      inputRef={register({
                        required: {
                          value: true,
                          message: 'First name is required!',
                        },
                      })}
                      inputtype="text"
                      placeholder="First Name"
                      errMsg={errors['firstName']?.message}
                      inputid="firstName"
                    />
                  </Col>

                  <Col xl="3" lg="4" md="6" xs="12">
                    <InputField
                      label="Last Name"
                      inputtype="text"
                      isRequired
                      inputRef={register({
                        required: {
                          value: true,
                          message: 'Last name is required!',
                        },
                      })}
                      placeholder="Last Name"
                      inputid="lastName"
                      errMsg={errors['lastName']?.message}
                    />
                  </Col>

                  <Col xl="3" lg="4" md="6" xs="12">
                    <InputField
                      label="Email"
                      inputtype="email"
                      placeholder="Email"
                      disabled={true}
                      inputRef={register()}
                      inputid="email"
                      errMsg={errors['email']?.message}
                    />
                  </Col>

                  <Col xl="3" lg="4" md="6" xs="12">
                    <InputField
                      label="Phone"
                      inputtype="text"
                      inputRef={register}
                      placeholder="Phone"
                      inputid="phone"
                    />
                  </Col>

                  <Col xl="3" lg="4" md="6" xs="12">
                    <InputField
                      label="Mobile Phone"
                      inputtype="text"
                      inputRef={register}
                      placeholder="Mobile Phone"
                      inputid="mobilePhone"
                    />
                  </Col>

                  <Col xl="3" lg="4" md="6" xs="12">
                    <InputField
                      label="LinkedIn URL"
                      inputtype="text"
                      placeholder="LinkedIn URL"
                      inputRef={register({
                        pattern: {
                          value: URL_REGEX,
                          message: 'LinkedIn URL is not valid!',
                        },
                      })}
                      errMsg={errors['linkedinURL']?.message}
                      inputid="linkedinURL"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" md="6" xs="12">
                    <div className="form-group">
                      <label>Quote</label>
                      <textarea
                        rows={5}
                        className="form-control"
                        name="quote"
                        ref={register}
                        placeholder="It’s about listening to the client and understanding each company has a unique culture then finding that ..."
                      ></textarea>
                    </div>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <div className="form-group">
                      <label>Bio</label>
                      <textarea
                        rows={5}
                        className="form-control"
                        name="bio"
                        ref={register}
                        placeholder="Kendra founded RealREPP in 2015 and has over 14 years of experience ..."
                      ></textarea>
                    </div>
                  </Col>
                </Row>

                <div className="form-actions pt-4">
                  <CustomButton
                    buttonColor="primary"
                    loading={loading || waiting}
                    disabled={loading || waiting}
                    buttonText="Save Changes"
                    className="big-width"
                  />
                </div>
              </Form>
            </div>
          </TabPane>

          <TabPane tabId="2" className="tab-pane-padding">
            <UpdatePasswordComponent />
          </TabPane>

          <TabPane tabId="3" className="tab-pane-padding">
            <RealReppDocuments />
          </TabPane>

          <TabPane tabId="4" className="tab-pane-padding">
            <div className="user-basic-form">
              {user?.outLookToken ? (
                <div className="d-flex justify-content-between flex-wrap gap-10">
                  <h6
                    className="mt-auto"
                    style={{ marginBottom: '0px', fontSize: '14px' }}
                  >
                    Your <strong>Outlook</strong> account is configured{' '}
                    <span className="text-success">successfully</span>
                    <span className="font-weight-bold d-flex mt-1">
                      <strong className="text-muted mr-2"> with:</strong>
                      {user?.outLookMail}
                    </span>
                  </h6>
                  <div>
                    <Button
                      className="btn-sm mb-2"
                      onClick={() => logoutOutlook()}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <h6
                    className="mb-2"
                    style={{ marginBottom: '0px', fontSize: '14px' }}
                  >
                    Kindly configure your <strong>Outlook</strong> account,
                    {outlookLink}
                  </h6>
                </>
              )}
              {
                userRoles?.includes("EXECUTIVE") &&
                <div className='mt-3'>
                  {
                    user?.outLookAccountingToken ?
                      (
                        <div className="d-flex justify-content-between flex-wrap gap-10">
                          <h6
                            className="mt-auto"
                            style={{ marginBottom: '0px', fontSize: '14px' }}
                          >
                            <strong>Accounting Outlook</strong> account is configured{' '}
                            <span className="text-success">successfully</span>
                            <span className="font-weight-bold d-flex mt-1">
                              <strong className="text-muted mr-2"> with:</strong>
                              {user?.outLookAccountingMail}
                            </span>
                          </h6>
                          <div>
                            <Button
                              className="btn-sm mb-2"
                              onClick={() => logoutOutlook(true)}
                            >
                              Logout
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <h6
                            className="mb-2"
                            style={{ marginBottom: '0px', fontSize: '14px' }}
                          >
                            Kindly configure <strong>Accounting Outlook</strong> account,
                            {outlookAccountingLink}
                          </h6>
                        </>
                      )
                  }
                </div>

              }
            </div>
            <div className="user-basic-form">
              {data?.me?.user?.email === DocusignExecutiveEmail &&
                !data?.me?.user?.docusignAccessToken && (
                  <h6 style={{ marginBottom: '0px', fontSize: ' 14px' }}>
                    Kindly configure your <strong>DocusSign</strong> account,
                    <Button
                      className=" bg-transparent text-primary border-0 p-0 ml-2 "
                      size="sm"
                      onClick={() => {
                        window.location.href = `${docusignURLForCode}?response_type=code&scope=signature&client_id=${docusignIKey}&redirect_uri=${docuSignRedirectURI}`;
                      }}
                    >
                      <u>Sign In</u>
                    </Button>
                  </h6>
                )}

              {data?.me?.user?.email === DocusignExecutiveEmail &&
                data?.me?.user?.docusignAccessToken && (
                  <div className="d-flex justify-content-between">
                    <h6
                      className="mt-auto"
                      style={{ marginBottom: '0px', fontSize: '14px' }}
                    >
                      Your <strong>DocuSign</strong> account is configured{' '}
                      <span className="text-success">successfully</span>
                    </h6>
                  </div>
                )}
            </div>
          </TabPane>

          <TabPane tabId="5" className="tab-pane-padding">
            <TjTalVideo />
          </TabPane>

          <TabPane tabId="6" className="tab-pane-padding">
            <Reports />
          </TabPane>

          <TabPane tabId="7" className="tab-pane-padding">
            <TjLookupSetting />
          </TabPane>

          <TabPane tabId="8" className="tab-pane-padding">
            <OpenGoalSheet />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default SettingsComponent;
